import React, { useEffect, useState } from 'react'
import { Box, Card, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from '@mui/material'
import atoms from '../../../components/atoms'
import classes from './index.module.scss'
import { ChangePassword, changeTab, updateAccountDetail } from '../../../redux/action'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom'
import ConfirmationModal from '../../../components/atoms/ConfirmationModal'
const { TextField, Button, Modal } = atoms
const Index = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isPasswordModal, setIsPasswordModal] = useState(false)
    const [IsPersonalDetailsSubmit, setIsPersonalDetailsSubmit] = useState(false)
    const [isChangePasswordSubmit, setIsChangePasswordSubmit] = useState(false)
    const [ChangePasswordPayload, setChangePasswordPaload] = useState('')
    const [isError, setIsError] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [newEmail, setNewEmail] = useState('')
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const loginResponse = localStorage.getItem('loginResponse');
    const userData = loginResponse ? JSON.parse(loginResponse) : {};
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    useEffect(() => {
        setFirstName(userData.first_name)
        setLastName(userData.last_name)
        setNewEmail(userData.email)
        dispatch(changeTab('Profile'))
    }, [])
    
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const validation: any = useFormik<any>({
        enableReinitialize: true,
        initialValues: {
            new_password: '',
            current_password: '',
        },
        validationSchema: Yup.object({
            current_password: Yup.string().required("Please Enter Current Password"),
            new_password: Yup.string()
                .required("Please Enter Password")
                .min(8, "Password must be at least 8 characters")
                .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
                .matches(/[a-z]/, "Password must contain at least one lowercase letter")
                .matches(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/, "Password must contain at least one special character")
                .matches(/[0-9]/, "Password must contain at least one number"),
        }),
        onSubmit: (values: any) => {
            setChangePasswordPaload(values)
            setIsChangePasswordSubmit(true)
        }
    });

 

    const updatePersonalDetail = () => {
        if (firstName === '' || lastName === '' || newEmail === '' || !emailRegex.test(newEmail)) {
            setIsError(true)
            return
        }
        let payload = {
            first_name: firstName,
            last_name: lastName,
            email: newEmail,
            // postCode: userData?.postCode,
            // school_name: userData?.school_name,
            // address: userData?.address,
            // role: userData?.role,
        }
        dispatch(updateAccountDetail(userData.id, payload, navigate, true))
        setIsPersonalDetailsSubmit(false)

    }
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };


    const updateForProfile = () => {
        dispatch(ChangePassword(ChangePasswordPayload, navigate))
        setIsPasswordModal(false)
        setIsChangePasswordSubmit(false)
    }

    return (
        <>
            <Grid sx={{ display: 'flex', justifyContent: 'start', }}>
                <Card sx={{ p: '25px', border: '3px solid #6a238203' }}>
                    <Grid display={'flex'} justifyContent={'space-between'} >
                        <Typography sx={{ fontSize: '22px', fontFamily: 'poppins', fontWeight: 'bold' }} >User Profile </Typography>
                        <Button variant={'outlined'} onClick={() => setIsPasswordModal(true)}>
                            Update Password
                        </Button>
                    </Grid>
                    <Grid container spacing={3} sx={{ mt: '10px' }}>
                        <Grid item xl={6} lg={6} sm={6} md={6}>
                            <TextField disabled={false} label={'First Name'} placeholder={'Enter the First Name'} value={firstName} onChange={(e: any) => setFirstName(e.target.value)} />
                            {isError && firstName?.length === 0 && <FormHelperText error>Please enter first name</FormHelperText>}
                        </Grid>
                        <Grid item xl={6} lg={6} sm={6} md={6}>
                            <TextField disabled={false} label={'Last Name'} placeholder={'Enter the Last Name'} value={lastName} onChange={(e: any) => setLastName(e.target.value)} />
                            {isError && lastName?.length === 0 && <FormHelperText error>Please enter first name</FormHelperText>}
                        </Grid>

                    </Grid >
                    <Grid container spacing={3} sx={{ mt: '10px' }}>
                        <Grid item xl={12} lg={12} sm={12} md={12}>
                            <TextField sx={{ width: '100%' }} disabled={true} label={'Email'} placeholder={'Enter the Email'} value={newEmail} onChange={(e: any) => setNewEmail(e.target.value)} />
                            {isError && newEmail?.length === 0 && <FormHelperText error>Please enter first name</FormHelperText>}
                            {!emailRegex.test(newEmail) && newEmail?.length !== 0 && <FormHelperText error>Please enter correct email</FormHelperText>}
                        </Grid>

                    </Grid>

                    <Grid container spacing={3} sx={{ mt: '10px' }}>
                        <Grid item xl={12} lg={12} sm={12} md={12}>
                            <TextField disabled={true} label={'Address'} placeholder={'Enter the Address'} value={userData?.address} fullWidth={true} multiline={true}
                                rows={3} />
                        </Grid>
                    </Grid>

                    <Grid sx={{ mt: '15px', display: 'flex', justifyContent: 'center', gap: '5px' }}>
                        <Button variant={"outlined"} onClick={() => navigate('/dashboard')}>Close</Button>
                        <Button variant={"contained"} onClick={() => {
                            setIsPersonalDetailsSubmit(true)
                        }}>Update</Button>
                    </Grid>
                </Card>
            </Grid>

            <>



                <ConfirmationModal
        open={IsPersonalDetailsSubmit}
        handleClose={()=> setIsPersonalDetailsSubmit(false)}
        title="Confirmation"
        message="Are you sure you want to Change Personal Details ?"
        onConfirm={() => updatePersonalDetail()}
        onCancel={()=> setIsPersonalDetailsSubmit(false)}
      />

       
                <ConfirmationModal
        open={isChangePasswordSubmit}
        handleClose={()=> setIsChangePasswordSubmit(false)}
        title="Confirmation"
        message="Are you sure you want to Change password ?"
        onConfirm={() => updateForProfile()}
        onCancel={()=> setIsChangePasswordSubmit(false)}
      />
            </>
            <>
                <Modal
                    open={isPasswordModal}
                    handleClose={() => setIsPasswordModal(false)}
                    title={'Update Password'}
                >

                    <Grid item className={classes.formCard} display={'flex'} justifyContent={"center"} alignItems={"center"} padding={"40px"}>
                        <form onSubmit={validation.handleSubmit} style={{ width: "100%" }}>
                            <Box  >
                                <InputLabel className={classes.text_start} htmlFor="outlined-adornment-password">Current Password</InputLabel>
                                <FormControl sx={{ width: '100%', }} variant="outlined">
                                    <OutlinedInput
                                        id="outlined-adornment-current_password"
                                        type={'password'}
                                        placeholder="Enter current password"
                                        {...validation.getFieldProps('current_password',)}
                                        error={validation.touched.current_password && Boolean(validation.errors.current_password)}
                                    />
                                </FormControl>
                                {validation.touched.current_password && validation.errors.current_password && (
                                    <FormHelperText error>{validation.errors.current_password}</FormHelperText>
                                )}
                            </Box>

                            <Box className={classes.mt_25} >
                                <InputLabel className={classes.text_start} htmlFor="outlined-adornment-password">New Password</InputLabel>
                                <FormControl sx={{ width: '100%', }} variant="outlined">
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Enter password"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    className={classes.eye_icon_bg_color}
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        {...validation.getFieldProps('new_password')}
                                        error={validation.touched.new_password && Boolean(validation.errors.new_password)}
                                    />
                                </FormControl>
                                {validation.touched.new_password && validation.errors.new_password && (
                                    <FormHelperText error>{validation.errors.new_password}</FormHelperText>
                                )}
                            </Box>
                            <Box sx={{ mt: '5px' }}>
                                <Typography variant="body2" component="small">
                                    Password must contain the following:
                                </Typography>
                                <ul >
                                    <li style={{ color: validation.values.new_password && validation.values.new_password.length >= 8 ? 'green' : 'red' }}>
                                        <Typography variant="body2" component="small">
                                            Minimum 8 characters
                                        </Typography>
                                    </li>
                                    <li style={{ color: /[A-Z]/.test(validation.values.new_password) ? 'green' : 'red' }}>
                                        <Typography variant="body2" component="small">
                                            At least one uppercase
                                        </Typography>
                                    </li>
                                    <li style={{ color: /[a-z]/.test(validation.values.new_password) ? 'green' : 'red' }}>
                                        <Typography variant="body2" component="small">
                                            At least one lowercase
                                        </Typography>
                                    </li>
                                    <li style={{ color: /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(validation.values.new_password) ? 'green' : 'red' }}>
                                        <Typography variant="body2" component="small">
                                            At least one special character
                                        </Typography>
                                    </li>
                                    <li style={{ color: /[0-9]/.test(validation.values.new_password) ? 'green' : 'red' }}>
                                        <Typography variant="body2" component="small">
                                            At least one number
                                        </Typography>
                                    </li>
                                    <li style={{ color: validation.values.new_password.length >= 8 && validation.values.new_password === confirmPassword ? 'green' : 'red' }}>
                                        <Typography variant="body2" component="small">
                                            Password and Confirm password must be the same
                                        </Typography>
                                    </li>
                                </ul>
                            </Box>

                            <Box className={classes.mt_25}>
                                <InputLabel className={classes.text_start} htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                                <FormControl sx={{ width: '100%', }} variant="outlined">
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        placeholder="Enter Confirm Password"
                                        onChange={(e: any) => setConfirmPassword(e.target.value)}
                                        value={confirmPassword}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                    className={classes.eye_icon_bg_color}
                                                >
                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        error={validation.values.new_password !== confirmPassword && Boolean("Password Not Match")}
                                    />
                                    {/* {validation.values.password && validation.values.password !== confirmPassword &&
                                  <FormHelperText error>Password Not Match</FormHelperText>} */}
                                </FormControl>
                            </Box>

                            <Grid display={"flex"} justifyContent={"center"} marginTop={'45px'} >
                                <Button disabled={validation.values.new_password !== confirmPassword} type={"submit"} variant={"contained"} sx={{ width: '80%', height: '55px' }} className={[classes.mt_25].join(' ')}>
                                    Submit
                                </Button>
                            </Grid>

                        </form>
                    </Grid>

                </Modal>
            </>
        </>
    )
}

export default Index