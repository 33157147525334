import React, { memo, useEffect, useState } from 'react'
import { Grid, Box, Card, Typography, CardContent, Divider, TextField, Tabs, Tab, InputLabel, FormGroup, FormControlLabel, Checkbox, Radio, RadioGroup } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import WarningIcon from '@mui/icons-material/Warning';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import classes from "./index.module.scss"
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from "reselect";
import { getReferalCaseloadByID, getUserList, addCaseload, setCurentTab } from '../../../redux/action';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import atoms from '../../atoms'
import { useFormik } from 'formik';
import * as Yup from "yup";
import { style } from './style'
import constant from '../../../constants/constant';
import { postcodeRegex } from '../../../constants/defaultValues'
import { ReferralCaseLoad } from './interface'
import { formatNHSNumber } from '../../../constants/utils';
import ConfirmationModal from '../../atoms/ConfirmationModal';
import { useSearch } from '../../../pages/App/Referral/Hooks/Usesearch';


const { Button, Modal, Select, Table, LightBox, Slider } = atoms
const referralReducer = (state: any) => state?.referralReducer
const CreateCaseload = ({ referralCaseload }: any) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params: any = useParams()
    const [hideWarning, setHideWarning] = useState(false)
    const [isAddClinicianModel, setIsAddClinicianModel] = useState(false);
    const [isNewSchool, setIsNewSchool] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false);
    const [isSchoolDetailFilled, setIsSchoolDetailFilled] = useState(false)
    const [clinicianIds, setClinicianIds] = useState([]);
    const [isSeletedClinician, setIsSeletedClinician] = useState([])
    const [selectClinicianList, setSelectClinicianList] = useState([])
    const [digital_literacy, setdigital_literacy] = useState()
    const [isHomeSchooling, setHomeSchooling] = useState()
    const [isCreateCaseload, setIsCreateCaseload] = useState(false)
    const [isEditDetail, setIsEditDetail] = useState(false)
    const [isEditParentDetail, setIsEditParentDetail] = useState(false)
    const [isNewParentDetail, setIsNewParentDetail] = useState<any>()
    const [gp_details, setGp_details] = useState<any>()
    const [isNewSchoolDetail, setIsNewSchoolDetail] = useState<any>({})
    const [isBackupSchoolDetail, setBackupSchoolDetail] = useState<any>({})
    const [additional_parent_carers_details, setadditionalParentDetail] = useState<any>({})
    const [isSelectedSchoolDetail, setIsSelectedSchoolDetail] = useState<any>('')
    const [isBackupNewSchool, setBackupNewSchool] = useState<any>(false)
    const [currentTab, setCurrentTab] = useState<string>('one')
    const [currentParentTab, setCurrentParentTab] = useState<string>('one')
    const { query, setQuery, setUserAccess } = useSearch();
    const [scope, setScope] = useState<any>('')
    const [placeholderObject, setPlaceholderObject] = useState({
        label:'Please Search by name atleast 3 characters',
        code_number: "placeholder",
        isDisabled: true,
      });
    const [columns, setColumns] = useState([
        {
            name: '#',
            cell: (item: any, ind: number) => <span>{ind + 1}</span>,
            width: '50px'

        },
        {
            name: 'Clinicians Name',
            cell: (item: any, ind: number) => <span>{item.label}</span>,
            sortable: false,
            minWidth: "25%"
        },
        {
            name: 'Email Address',
            cell: (item: any, ind: number) => <span>{item.email}</span>,
            sortable: false,
            minWidth: "26%"
        },
        {
            name: 'Job Role',
            cell: (item: any, ind: number) => <span>{item.jobRole}</span>,
            sortable: false,
            minWidth: "34%"
        },

        {
            name: 'Action',
            cell: (item: any, ind: number) => <span>{item.action}</span>,
            sortable: false
        }
    ]);
    let schoolInitialValues = {
        first_name: '',
        last_name: '',
        email: '',
        postCode: '',
        phone_no: '',
        address: '',
        school_name: '',
        value: '',
        label: '',
        role: constant.ROLE.ALL_ACCESS,
        scope: constant.SCOPE.CLINICIAN
    }
    const userReducer = (state: any) => state?.userManagementReducer
    const getCustomDropdown = (response: any, name: string, last: string) => {
        let dropdown: any = []
        response && response?.map((value: any) => {
            if (isEditDetail) {
                dropdown.push({
                    "value": value.id,
                    "label": last ? value[name] + ' ' + value[last] : value[name],
                    'first_name': value.first_name || value[name],
                    "last_name": value[last] || value.last_name,
                    "email": value.email,
                    "address": value.address,
                    "jobRole": value.jobRole,
                    "school_name": value.school_name,
                    "phone_no": value.phone_no,
                })
            } else {
                dropdown.push({
                    "value": value.id,
                    "label": last ? value[name] + ' ' + value[last] : value[name],
                    "email": value.email,
                    "jobRole": value.jobRole,
                    "phone_no": value.phone_no,
                })
            }
        })
        return dropdown
    }


    const userData = createSelector(
        userReducer,
        (state) => ({
            userList: scope === constant.SCOPE.SCHOOL ? getCustomDropdown(state?.userData?.user, "school_name", "") : getCustomDropdown(state?.userData?.user, "first_name", "last_name"),

        })
    );
    const { userList } = useSelector(userData);

    const validation = useFormik<ReferralCaseLoad>({
        enableReinitialize: true,
        initialValues: schoolInitialValues,
        validationSchema: Yup.object({
            first_name: Yup.string().required("Please Enter First Name"),
            last_name: Yup.string().required("Please Enter Last Name"),
            address: Yup.string().required("Please Enter Address"),
            school_name: Yup.string().required("Please Enter School Name"),
            email: Yup.string().required("Please Enter Email"),
            postCode: Yup.string()
                .matches(postcodeRegex, 'Invalid postcode format')
                .required("Please Enter Postcode"),
            phone_no: Yup.number().required("Please Enter Mobile Number"),

            // modules: Yup.string().required("Please Select"),

        }),
        onSubmit: (values: ReferralCaseLoad) => {
            setIsNewSchoolDetail('')
            setBackupSchoolDetail(values)
            setBackupNewSchool(isNewSchool)

            setIsSchoolDetailFilled(true)
            setHideWarning(false)
            setIsEditDetail(false)
        }
    });

    const validationParent = useFormik<ReferralCaseLoad>({
        enableReinitialize: true,
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            postCode: '',
            phone_no: '',
            role: constant.ROLE.ALL_ACCESS,
            scope: constant.SCOPE.CLINICIAN
        },
        validationSchema: Yup.object({
            first_name: Yup.string().required("Please Enter First Name"),
            last_name: Yup.string().required("Please Enter Last Name"),
            email: Yup.string().required("Please Enter Email"),
            phone_no: Yup.number().required("Please Enter Mobile Number"),
        }),
        onSubmit: (values: ReferralCaseLoad) => {
            setIsNewParentDetail(values)
            setIsEditParentDetail(false)
        }
    });

    useEffect(() => {
        if (!referralCaseload) return;
        const { school_detail, parent_detail, isNewSchool, additional_parent_carers_details, digital_literacy, gp_details, isHomeSchooling } = referralCaseload;
        const values = {
            first_name: school_detail?.first_name,
            last_name: school_detail?.last_name,
            email: school_detail?.contact_email || school_detail?.email,
            postCode: school_detail?.post_name,
            phone_no: school_detail?.contact_phone || school_detail?.phone_no,
            address: school_detail?.address,
            school_name: school_detail?.school_name,
            value: '',
            label: '',
        };

        const parentValues = {
            first_name: parent_detail?.first_name,
            last_name: parent_detail?.last_name,
            email: parent_detail?.email,
            phone_no: parent_detail?.contact_phone || parent_detail?.phone_no,
        };

        setIsNewParentDetail(parent_detail)
        setHomeSchooling(isHomeSchooling)
        setIsNewSchoolDetail(school_detail)
        setadditionalParentDetail(additional_parent_carers_details)
        setBackupSchoolDetail(school_detail)
        setdigital_literacy(digital_literacy)
        setGp_details(gp_details)

        validation.setValues(values);
        validationParent.setValues(parentValues);
        setBackupNewSchool(isNewSchool);
        setIsNewSchool(isNewSchool)
        if (isNewSchool === false) {
            setCurrentTab('two')
        } else {
            setCurrentTab('one')
            setHideWarning(true)
        }
    }, [referralCaseload]);


    const addClinician = () => {
        let tempClinicianList: any = [];
        let tempClinicianIds: any = [];
        if (isSeletedClinician) {
            tempClinicianList = tempClinicianList.concat(isSeletedClinician);
        }

        let Ids = isSeletedClinician?.map((item: any) => item?.value);
        if (Ids) {
            tempClinicianIds = tempClinicianIds.concat(Ids);
        }
        tempClinicianIds.filter((v: any, i: any, a: any) => a.findIndex((v2: string) => (v2 === v)) === i)
        tempClinicianList.filter((v: any, i: any, a: any) => a.findIndex((v2: any) => (v2.value === v.value)) === i)
        setSelectClinicianList(tempClinicianList);
        setClinicianIds(tempClinicianIds);
        setIsAddClinicianModel(false);

    }

    const removeClinician = (index: number) => {
        let tempClinicianList: any = [...selectClinicianList];
        let tempSeletedClinician: any = [...isSeletedClinician];
        let tempClinicianIds: any = [...clinicianIds];

        tempClinicianList.splice(index, 1);
        tempClinicianIds.splice(index, 1);
        tempSeletedClinician.splice(index, 1);

        setSelectClinicianList(tempClinicianList);
        setIsSeletedClinician(tempSeletedClinician);
        setClinicianIds(tempClinicianIds);
        setIsAddClinicianModel(false);
    }


    const createCaseload = () => {
        let payload = {
            school_detail: isBackupSchoolDetail,
            parent_detail: isNewParentDetail,
            digital_literacy: digital_literacy,
            additional_parent_carers_details: additional_parent_carers_details,
            isNewSchool: isNewSchool,
            isHomeSchooling: referralCaseload?.isHomeSchooling,
            patient_name: referralCaseload?.patient_detail?.name,
            patient_dob: referralCaseload?.patient_detail?.dob,
            nhs_number: referralCaseload?.patient_detail?.nhs_number,
            patient_gender: referralCaseload?.patient_detail?.gender,
            patient_email: referralCaseload?.patient_detail?.email,
            patient_address: referralCaseload?.patient_detail?.address,
            referral_id: params.id,
            clinician_ids: clinicianIds,
            gp_address: gp_details?.address,
            gp_name: gp_details?.name
        }

        dispatch(addCaseload(payload, navigate))
        dispatch(setCurentTab(1))

    }


    const handleSelect = (e: any) => {
        setIsNewSchoolDetail(e)
        setIsSelectedSchoolDetail(e)
    }

    const handleSchool = () => {
        setBackupSchoolDetail(isNewSchoolDetail)
        setBackupNewSchool(isNewSchool);
        setIsEditDetail(false)
    }

    const handleSetSchool = () => {
        validation.resetForm()
        setIsEditParentDetail(false)
        if (isBackupNewSchool) {
            validation.setValues(isBackupSchoolDetail)
        } else {
            setIsNewSchoolDetail(isBackupSchoolDetail)
        }
        setIsNewSchool(isBackupNewSchool)
        dispatch(getUserList({ scope: constant.SCOPE.SCHOOL }))
        setScope(constant.SCOPE.SCHOOL)
        setIsEditDetail(true)
    }

    useEffect(() => {
        if (isNewSchool === false) {
            setIsSelectedSchoolDetail(isNewSchoolDetail)
        } else {
            setIsSelectedSchoolDetail('')
        }
    }, [isNewSchool])

    const handleChange = (event: any) => {
        setCurrentTab(event.target.value);
        setIsNewSchool(event.target.value === 'one');
    };

    const allschoolData = createSelector(
        referralReducer,
        (state) => ({
            schooList: getCustomDropdown(state?.allschoolData?.user, "school_name", "")
        })
        
    );

    const { schooList } = useSelector(allschoolData);
     
    useEffect(() => {
        const timeout = setTimeout(() => {
            setDataLoaded(true);
        }, 1000);

        return () => clearTimeout(timeout);
    }, []);


    const handleSearchGp = (value: string) => {
        setUserAccess('ques22');
        setQuery(value);
      };
  
      const filteredOptions = query?.length >= 3 ? schooList : [];
    return (
        <Grid className={classes.clWrapper}>
            <Card variant="outlined" sx={{ borderRadius: "10px" }}>
                <Box padding={2} >
              
                    <Grid container spacing={1} alignItems="center" justifyContent="space-between" >
                        <Grid item sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
                            <Typography variant="subtitle1"><strong>Patient Name :</strong></Typography>
                            <Typography sx={{ color: "#444C55" }}>{referralCaseload?.patient_detail?.name}</Typography>
                        </Grid>
                        <Grid item>
                            <Grid item sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
                                <Typography variant="subtitle1"><strong>DOB :</strong></Typography>
                                <Typography sx={{ color: "#444C55" }}>{moment(referralCaseload?.patient_detail?.dob).format('DD/MM/YYYY')}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid item sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
                                <Typography variant="subtitle1"><strong>Gender :</strong></Typography>
                                <Typography sx={{ color: "#444C55" }}>{referralCaseload?.patient_detail?.gender}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid item sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
                                <Typography variant="subtitle1"><strong>NHS Number :</strong></Typography>
                                <Typography sx={{ color: "#444C55" }}>{formatNHSNumber(referralCaseload?.patient_detail?.nhs_number)}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Card>


            <Box
                sx={{
                    // border: "1px solid #E7E7E7",
                    borderRadius: "10px", mt: 3
                }}
            >


                <Grid
                    container
                    columns={12}
                    // padding={2}
                    sx={{ mt: { md: "10px" }, my: { md: "0" } }}
                >
                    <Grid item xs={12} md={6}
                        sx={{ pr: { md: "10px" } }}
                    >
                        {/* Parent/Carer Details Card */}
                        <Card variant="outlined" sx={style.card_custom}>
                            <Slider showStatus={false} showIndicators={additional_parent_carers_details !== null ? true : false} showArrows={additional_parent_carers_details !== null ? true : false}>
                                <CardContent>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.clTitle}>
                                        <Typography variant="h6">1. Parent/Carer Details</Typography>
                                        <ModeEditIcon
                                            onClick={() => {
                                                validationParent.resetForm()
                                                validationParent.setValues(isNewParentDetail)
                                                setIsEditParentDetail(true)
                                                dispatch(getUserList({ scope: constant.SCOPE.PARENT }))
                                                setScope(constant.SCOPE.PARENT)
                                            }}
                                            sx={{ cursor: 'pointer', color: '#6A2382' }} />
                                    </Box>
                                    {/* <Divider /> */}
                                    <Box>
                                        <Box display="flex" justifyContent="space-between" alignItems="center" my={"5px"}>
                                            <Typography variant="subtitle1">Name: </Typography>
                                            <Typography sx={style.font_custom_style} >{(isNewParentDetail?.first_name ? isNewParentDetail?.first_name : '') + ' ' + (isNewParentDetail?.last_name ? isNewParentDetail?.last_name : '')}</Typography>

                                        </Box>
                                        <Divider />
                                        <Box display="flex" justifyContent="space-between" alignItems="center" my={"5px"}>
                                            <Typography variant="subtitle1">Email: </Typography>
                                            <Typography sx={style.font_custom_style} >{isNewParentDetail?.email}</Typography>
                                        </Box>
                                        <Divider />
                                        <Box display="flex" justifyContent="space-between" alignItems="center" my={"5px"}>
                                            <Typography variant="subtitle1">Contact Number: </Typography>
                                            <Typography sx={style.font_custom_style} >{isNewParentDetail?.phone_no || isNewParentDetail?.phone}</Typography>
                                        </Box>
                                    
                                        <Box display="flex" justifyContent="space-between" alignItems="center" my={"23px"}>
                                        </Box>
                                    </Box>
                                </CardContent>

                                {additional_parent_carers_details !== null ? (<CardContent>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.clTitle}>
                                        <Typography variant="h6">1. Additional Parent/Carer Details</Typography>

                                    </Box>
                                    <Divider />
                                    <Box>
                                        <Box display="flex" justifyContent="space-between" alignItems="center" my={"5px"}>
                                            <Typography variant="subtitle1">Name: </Typography>
                                            <Typography sx={style.font_custom_style} >{(additional_parent_carers_details?.first_name ? additional_parent_carers_details?.first_name : '')}</Typography>

                                        </Box>
                                        <Divider />
                                        <Box display="flex" justifyContent="space-between" alignItems="center" my={"5px"}>
                                            <Typography variant="subtitle1">Email: </Typography>
                                            <Typography sx={style.font_custom_style} >{additional_parent_carers_details?.email}</Typography>
                                        </Box>
                                        <Divider />
                                        <Box display="flex" justifyContent="space-between" alignItems="center" my={"5px"}>
                                            <Typography variant="subtitle1">Contact Number: </Typography>
                                            <Typography sx={style.font_custom_style} >{additional_parent_carers_details?.phone_no || additional_parent_carers_details?.phone}</Typography>
                                        </Box>
                                   
                                        <Box display="flex" justifyContent="space-between" alignItems="center" my={"23px"}>
                                        </Box>
                                    </Box>
                                </CardContent>) : null}
                            </Slider>

                        </Card>
                    </Grid>

                    <Grid item xs={12} md={6}
                        sx={{ pl: { md: "10px" } }}
                    >
                        {/* Parent/Carer Details Card */}
                        <Card variant="outlined" sx={style.card_custom}>
                            <CardContent>
                                <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.clTitle} marginBottom={'1rem'}>
                                    <Grid sx={{ display: 'flex', gap: '5px', alignItems: 'start' }}><Typography variant="h6" sx={{ mb: '0!important', mr: 2 }}>2. Education Setting Details</Typography>
                                        {/* {hideWarning && <WarningIcon sx={{ color: '#ff4949' }} />} */}
                                  
                                        {(isBackupSchoolDetail?.status === constant.STATUS.NOT_REGISTERED) && <Typography variant="caption" sx={{ background: '#ff4949', borderRadius: '1rem', padding: '0.1rem 0.7rem', color: ' #fff', fontWeight: 600, fontSize: '0.7rem' }}>Not Registered</Typography>}
                                        {dataLoaded && !isBackupSchoolDetail?.email && !isHomeSchooling && <Typography variant="caption" sx={{ background: '#cd9713', borderRadius: '1rem', padding: '0.1rem 0.7rem', color: ' #fff', fontWeight: 600, fontSize: '0.7rem' }}>No Access</Typography>}
                                        {isHomeSchooling && <Typography component={'span'} sx={{ color: '#6A2382', fontFamily: 'poppins', display: 'flex', gap: '0.5rem', alignItems: 'center', fontSize: '0.8rem' }}>(<HomeWorkIcon />  Elective Home Education / Not Currently in Formal Education)</Typography>}
                                    </Grid>
                                    <AddCircleIcon onClick={() => {
                                        handleSetSchool()
                                    }} sx={{ cursor: 'pointer', color: '#6A2382' }} />

                                </Box>
                                {/* <Divider /> */}
                                <Box display="flex" justifyContent="space-between" alignItems="center" my={"5px"}>
                                    <Typography variant="subtitle1">Education Name: </Typography>
                                    <Typography sx={style.font_custom_style} >{isBackupSchoolDetail?.school_name || isBackupSchoolDetail?.label}</Typography>
                                </Box>
                                <Divider />
                                <Box display="flex" justifyContent="space-between" alignItems="center" my={"5px"}>
                                    <Typography variant="subtitle1">Contact Person: </Typography>
                                    <Typography sx={style.font_custom_style} > {isBackupSchoolDetail?.contact_person_name || isBackupSchoolDetail?.first_name ? isBackupSchoolDetail?.first_name + ' ' + isBackupSchoolDetail?.last_name : ''}</Typography>
                                </Box>
                                <Divider />
                                <Box display="flex" justifyContent="space-between" alignItems="center" my={"5px"}>
                                    <Typography variant="subtitle1">Contact Email: </Typography>
                                    <Typography sx={style.font_custom_style} > {isBackupSchoolDetail?.email}</Typography>
                                </Box>
                                <Divider />
                                <Box display="flex" justifyContent="space-between" alignItems="center" my={"5px"}>
                                    <Typography variant="subtitle1">Contact Number: </Typography>
                                    <Typography sx={style.font_custom_style} >{isBackupSchoolDetail?.contact_phone || isBackupSchoolDetail?.phone_no}</Typography>
                                </Box>
                                <Divider />
                                <Box display="flex" justifyContent="space-between" alignItems="center" my={"5px"}>
                                    <Typography variant="subtitle1">Address: </Typography>
                                    <Typography sx={style.font_custom_style}>{isBackupSchoolDetail?.address}</Typography>
                                </Box>

                                {/* <Box display="flex" justifyContent="space-between" alignItems="center" mt={"20px"} className={classes.markBtn}>
                                    <Button variant={'outlined'} fullWidth={true} sx={schoolVerified && style.non_verified_button} onClick={() => setSchoolVerified(!schoolVerified)} >
                                        Mark As {!schoolVerified ? "Verified" : "Unverified"}
                                    </Button>
                                </Box> */}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid sx={{ py: { md: "20px" } }}>
                    <Card variant="outlined" sx={style.card_custom}>
                        <CardContent>
                            <Grid sx={{ display: 'flex', justifyContent: 'space-between', mb: '15px', alignItems: 'center' }} className='innerheader'>
                                <Grid >
                                    <Typography variant="h6">Assign Clinicians</Typography>
                                </Grid>
                                <Grid >
                                    <Button variant="outlined" className="  btnStyle_v2" startIcon={<AddCircleIcon />} sx={{ borderRadius: "60px" }} onClick={() => {
                                        dispatch(getUserList({ scope: constant.SCOPE.CLINICIAN }))
                                        setScope(constant.SCOPE.CLINICIAN)
                                        setIsAddClinicianModel(true)
                                    }}>Add Clinician</Button>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid sx={{'& .tableFixeHeight':{
                        overflow:'hidden!important'
                    }}}>
                                <Table
                                    className={classes.customFixeHeight}
                                    data={selectClinicianList && selectClinicianList?.map((item: any, index: number) => {
                                        return {
                                            ...item,
                                            action: <HighlightOffIcon onClick={() => removeClinician(index)} sx={{ color: '#F15046', cursor: 'pointer' }} />
                                        };
                                    })}
                                    columns={columns}
                                />
                            </Grid>
                        </CardContent>
                    </Card>

                </Grid>

                <Grid container spacing={3} sx={{ p: '10px' }}>
                    <Grid item md={6} lg={6} className={classes.cancelBtn} sx={{}}>
                    <Button variant={'outlined'} fullWidth={true} sx={style.non_verified_button} onClick={()=> navigate('/manage-caseload/active')}>
                            Close
                        </Button>

                    </Grid>
                    <Grid item md={6} lg={6}>
                        <Button variant={'contained'} fullWidth={true} disabled={(isBackupNewSchool && !isSchoolDetailFilled)} onClick={() => setIsCreateCaseload(true)} >
                            Accept Referral
                        </Button>
                    </Grid>
                </Grid>
            </Box >

            <>
                <Modal open={isAddClinicianModel} handleClose={() => {

                    setIsAddClinicianModel(false)
                    // setIsSeletedClinician('')
                }} title={"Add Clinicians"}>
                    <Box className={classes.clinicModal}>
                        <Grid sx={{ width: '100%' }}>
                            <Select placeholder={isSeletedClinician.length === 0 ? "Select Clinicians" : ""}   onInputChange={(e:any)=> e} isMulti={true} disableCloseOnSelect={true} label={"Clinicians"}
                                defaultValue={isSeletedClinician} options={userList} onChange={(e: any) => setIsSeletedClinician(e)} />
                        </Grid>
                        <Grid className={classes.btnWrapper} sx={{ display: 'flex', justifyContent: 'center', alignItem: 'center', mt: '15px' }}>
                            <Button variant={"contained"} onClick={() => addClinician()} >Add Clinicians</Button>
                        </Grid>

                    </Box>
                </Modal>
            </>

            <>


<ConfirmationModal
        open={isCreateCaseload}
        handleClose={()=> setIsCreateCaseload(false)}
        title="Confirmation"
        message="Are you sure you want to Accept Referral ?"
        onConfirm={() => createCaseload()}
        onCancel={()=> setIsCreateCaseload(false)}
      />
            </>


            {/* School detail popup  */}

            <>
                <LightBox title={'Assign School'} open={isEditDetail} handleClose={() => { setIsEditDetail(false) }} >
                    <Box className={classes.clinicModal}>
                        <Grid sx={{ width: '100%', textAlign: 'center' }}>
                        </Grid>
                        <Grid className={classes.btnWrapper} sx={{ p: '' }}>
                            <Grid>
                                <form onSubmit={validation.handleSubmit}>
                                    {/* <Grid>
                                        <FormGroup>
                                            <FormControlLabel onChange={(e: any) => {
                                                validation.resetForm()
                                                setIsNewSchoolDetail('')
                                                setIsNewSchool(e.target.checked)
                                            }}
                                                control={<Checkbox checked={isNewSchool} />} sx={{ fontWeight: 600 }} label="New School" />
                                        </FormGroup>
                                    </Grid> */}
                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <RadioGroup
                                            value={currentTab}
                                            onChange={handleChange}
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                        >
                                            <FormControlLabel
                                                value="one"
                                                control={<Radio />}
                                                label="New School"
                                                style={{ marginBottom: 0 }}
                                                sx={isNewSchool ? style.label_color : {}}
                                            />
                                            <FormControlLabel
                                                value="two"
                                                control={<Radio />}
                                                label="Existing School"
                                                style={{ marginBottom: 0 }}
                                                sx={!isNewSchool ? style.label_color : {}}
                                            />
                                        </RadioGroup>
                                    </Box>
                                    {currentTab === "one" && <>
                                        <Grid container spacing={3} className={classes.mt_3}>
                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                <Box>
                                                    <InputLabel sx={style.label_color} >Contact Person First Name</InputLabel>
                                                    <TextField
                                                        fullWidth
                                                        placeholder="Enter First Name"
                                                        {...validation.getFieldProps('first_name')}
                                                        error={validation.touched.first_name && Boolean(validation.errors.first_name)}
                                                        helperText={validation.touched.first_name ? validation.errors.first_name : ''}
                                                        disabled={!isNewSchool}
                                                    />
                                                </Box>

                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                <Box>
                                                    <InputLabel sx={style.label_color} >Contact Person Last Name</InputLabel>
                                                    <TextField
                                                        fullWidth
                                                        placeholder="Enter Last Name"
                                                        {...validation.getFieldProps('last_name')}
                                                        error={validation.touched.last_name && Boolean(validation.errors.last_name)}
                                                        helperText={validation.touched.last_name && validation.errors.last_name}
                                                        disabled={!isNewSchool}
                                                    />
                                                </Box>

                                            </Grid>

                                        </Grid>

                                        <Grid container spacing={3} className={classes.mt_3}>
                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                <Box>
                                                    <InputLabel sx={style.label_color} >School Name</InputLabel>
                                                    <TextField
                                                        fullWidth
                                                        type={"text"}
                                                        placeholder="Enter School Name"
                                                        {...validation.getFieldProps('school_name')}
                                                        error={validation.touched.school_name && Boolean(validation.errors.school_name)}
                                                        helperText={validation.touched.school_name && validation.errors.school_name}
                                                        disabled={!isNewSchool}
                                                    />
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                <Box>
                                                    <InputLabel sx={style.label_color}>Email</InputLabel>
                                                    <TextField
                                                        fullWidth
                                                        type={"email"}
                                                        placeholder="Enter Email"
                                                        {...validation.getFieldProps('email')}
                                                        error={validation.touched.email && Boolean(validation.errors.email)}
                                                        helperText={validation.touched.email && validation.errors.email}
                                                        disabled={!isNewSchool}

                                                    />
                                                </Box>

                                            </Grid>
                                        </Grid>

                                        <Grid className={classes.mt_3}>
                                            <Box>
                                                <InputLabel sx={style.label_color} >Address</InputLabel>
                                                <TextField
                                                    fullWidth
                                                    type="text"
                                                    placeholder="Enter Address"
                                                    {...validation.getFieldProps('address')}
                                                    error={validation.touched.address && Boolean(validation.errors.address)}
                                                    helperText={
                                                        validation.touched.address && validation.errors.address
                                                            ? String(validation.errors.address) // Convert errors to string
                                                            : "" // Empty string if there are no errors
                                                    }
                                                    rows={3}
                                                    disabled={!isNewSchool}
                                                    multiline={true}
                                                    inputProps={{ maxLength: 500 }}
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid container spacing={3} className={classes.mt_3}>
                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                <Box>
                                                    <InputLabel sx={style.label_color} >Contact Person Mobile</InputLabel>
                                                    <TextField
                                                        fullWidth
                                                        type="number"
                                                        placeholder="Enter mobile"
                                                        {...validation.getFieldProps('phone_no')}
                                                        error={validation.touched.phone_no && Boolean(validation.errors.phone_no)}
                                                        helperText={validation.touched.phone_no && validation.errors.phone_no}
                                                        disabled={!isNewSchool}
                                                    />
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                <Box>
                                                    <InputLabel sx={style.label_color} >Postcode</InputLabel>
                                                    <TextField
                                                        fullWidth
                                                        type="text"
                                                        placeholder="Enter Postcode"
                                                        {...validation.getFieldProps('postCode')}
                                                        error={validation.touched.postCode && Boolean(validation.errors.postCode)}
                                                        helperText={validation.touched.postCode && validation.errors.postCode}
                                                        disabled={!isNewSchool}
                                                    />
                                                </Box>

                                            </Grid>

                                        </Grid>
                                    </>}
                                    {/* {!isEditParentDetail && <Grid sx={{ mt: '30px', mb: '20px', textAlign: 'center' }}>
                                        <Typography sx={style.label_color}>OR</Typography>
                                    </Grid>} */}
                                    {currentTab === "two" && <Grid width={'100%'} className={classes.mt_3}>
                                        <InputLabel sx={style.label_color} >School</InputLabel>
                                        {/* <Select placeholder={"Select..."} isDisabled={isNewSchool} disableCloseOnSelect={false}
                                            defaultValue={isSelectedSchoolDetail?.school_name} options={userList} onChange={(e: any) =>
                                                handleSelect(e)
                                            } /> */}

<Select placeholder={"Enter atleast 3 characters"}   onInputChange={(e: any) => handleSearchGp(e)
              } isDisabled={isNewSchool} disableCloseOnSelect={false}
                                            defaultValue={isSelectedSchoolDetail?.school_name}   options={[placeholderObject, ...filteredOptions]} onChange={(e: any) =>
                                                handleSelect(e)
                                            } />
                                    </Grid>}
                                    <Grid className={`${classes.btnClr}`} sx={{ mt: '30px', textAlign: 'center' }}>

                                        {isNewSchool ? <Button type={"submit"} fullWidth={false}
                                            variant={"contained"}>
                                            Add & Assign
                                        </Button> :
                                            <Button fullWidth={false} variant={"contained"} onClick={() => handleSchool()}>
                                                Assign
                                            </Button>
                                        }
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>

                    </Box>
                </LightBox>
            </>

            {/* Parent detail popup  */}

            <>
                {
                    <LightBox title={'Assign Parent'} open={isEditParentDetail} handleClose={() => { setIsEditParentDetail(false) }} >
                        <Box className={classes.clinicModal}>
                            <Grid sx={{ width: '100%', textAlign: 'center' }}>
                            </Grid>
                            <Grid className={classes.btnWrapper} sx={{ p: '' }}>
                                <Grid>
                                    <form onSubmit={validationParent.handleSubmit}>
                                        <Grid container spacing={3} className={classes.mt_3}>
                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                <Box>
                                                    <InputLabel sx={style.label_color} > First Name</InputLabel>
                                                    <TextField
                                                        fullWidth
                                                        placeholder="Enter First Name"
                                                        {...validationParent.getFieldProps('first_name')}
                                                        error={validationParent.touched.first_name && Boolean(validationParent.errors.first_name)}
                                                        helperText={validationParent.touched.first_name ? validationParent.errors.first_name : ''}

                                                    />
                                                </Box>

                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                <Box>
                                                    <InputLabel sx={style.label_color} > Last Name</InputLabel>
                                                    <TextField
                                                        fullWidth
                                                        placeholder="Enter Last Name"
                                                        {...validationParent.getFieldProps('last_name')}
                                                        error={validationParent.touched.last_name && Boolean(validationParent.errors.last_name)}
                                                        helperText={validationParent.touched.last_name && validationParent.errors.last_name}

                                                    />
                                                </Box>

                                            </Grid>

                                        </Grid>

                                        <Grid container spacing={3} className={classes.mt_3}>
                                            <Grid item xs={12} sm={6} md={6} lg={6} >
                                                <Box>
                                                    <InputLabel sx={style.label_color}>Email</InputLabel>
                                                    <TextField
                                                        fullWidth
                                                        type={"email"}
                                                        placeholder="Enter Email"
                                                        {...validationParent.getFieldProps('email')}
                                                        error={validationParent.touched.email && Boolean(validationParent.errors.email)}
                                                        helperText={validationParent.touched.email && validationParent.errors.email}


                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                                <Box>
                                                    <InputLabel sx={style.label_color} >Mobile</InputLabel>
                                                    <TextField
                                                        fullWidth
                                                        type="number"
                                                        placeholder="Enter mobile"
                                                        {...validationParent.getFieldProps('phone_no')}
                                                        error={validationParent.touched.phone_no && Boolean(validationParent.errors.phone_no)}
                                                        helperText={validationParent.touched.phone_no && validationParent.errors.phone_no}

                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid className={`${classes.btnClr}`} sx={{ mt: '30px', textAlign: 'center' }}>
                                            <Button type={"submit"} fullWidth={false} variant={"contained"} >
                                                Assign
                                            </Button>
                                        </Grid>
                                    </form>
                                </Grid>
                            </Grid>

                        </Box>
                    </LightBox>
                }</>
        </Grid >
    )
}

export default memo(CreateCaseload)