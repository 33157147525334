import React, { useEffect, useState } from 'react';
import { Card, Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, TextField, Typography, Select, MenuItem } from '@mui/material';
import classes from "./index.module.scss"
import CommonField from './CommonField';
const Previous_assessments = ({ questions, patientName, setQuestionData, isError, setIsError,setIsDisableContinue }: any) => {
  let QuestionNumber = questions.personalDetails.length + questions.familyHistory.length + questions.communication.length + 
  questions.socialInteraction.length + questions.playImagination.length + questions.sensory.length + questions.motorMannerisms.length
  + questions.attentionActivity.length + questions.birthDetails.length + questions.education.length + questions.mental.length
  const handleAnswerChange = (index: number, value: any, key: string) => {
    let tempQuestions = { ...questions };
    let tempPersonDetail = [...tempQuestions.previousAssessments];
    tempPersonDetail[index].question = tempPersonDetail[index].question.replace('[forename]', patientName);
    tempPersonDetail[index].answer = value;
    setQuestionData({ ...tempQuestions, previousAssessments: tempPersonDetail });
  };


  useEffect(() => {
    setIsError(false)
  }, [])
  return (
    <Grid container spacing={3} className={classes.Referral}>

      {questions?.previousAssessments && questions?.previousAssessments?.map((ques: any, index: number) => (
        <>
          {/* {handleRenderComponent(ques.type, index, ques.question, ques.answer, ques.option, "previousAssessments", ques.isRequired)} */}
          {<CommonField
            type={ques.type}
            index={index}
            question={ques.question.replace('[forename]',patientName)}
            answer={ques.answer}
            options={ques.option}
            key={"previousAssessments"}
            required={ques.isRequired}
            question_number={QuestionNumber}
            questions={questions?.previousAssessments}
            isError={isError}
            handleAnswerChange={handleAnswerChange}
            ques={ques}
            setIsDisableContinue={setIsDisableContinue}
            totalQuestion={questions?.previousAssessments?.length}
          />}
          {/* {isError && ques.answer === "" && ques.isRequired && <p className={classes.errorColor}>This field is required</p>} */}
        </>
      ))}
    </Grid>
  );
}

export default Previous_assessments;
