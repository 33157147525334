import React, { useEffect, useState } from 'react';
import { Card, Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, TextField, Typography, Select, MenuItem } from '@mui/material';
import classes from "./index.module.scss"
import CommonField from './CommonField';
const FamilyHistory = ({ questions,patientName, setQuestionData, isError, setIsError,setIsDisableContinue }: any) => {
  let QuestionNumber = questions.personalDetails.length
  const handleAnswerChange = (index: number, value: any, key: string) => {
    let tempQuestions = { ...questions };
    let tempPersonDetail = [...tempQuestions.familyHistory];
    tempPersonDetail[index].question = tempPersonDetail[index].question.replace('[forename]', patientName);
    tempPersonDetail[index].answer = value;
    setQuestionData({ ...tempQuestions, familyHistory: tempPersonDetail });
  };


  useEffect(() => {
    setIsError(false)
  }, [])
  return (
    <Grid container spacing={3} className={classes.Referral}>

      {questions?.familyHistory && questions?.familyHistory?.map((ques: any, index: number) => (
        <>
          {/* {handleRenderComponent(ques.type, index, ques.question, ques.answer, ques.option, "familyHistory", ques.isRequired)} */}
          {<CommonField
            type={ques.type}
            index={index}
            question={ques.question.replace('[forename]',patientName)}
            answer={ques.answer}
            options={ques.option}
            key={"familyHistory"}
            required={ques.isRequired}
            question_number={QuestionNumber}
            questions={questions?.familyHistory}
            isError={isError}
            handleAnswerChange={handleAnswerChange}
            ques={ques}
            setIsDisableContinue={setIsDisableContinue}
            totalQuestion={questions?.familyHistory?.length}
          />}
          {/* {isError && ques.answer === "" && ques.isRequired && <p className={classes.errorColor}>This field is required</p>} */}
        </>
      ))}
    </Grid>
  );
}

export default FamilyHistory;
