import React, { useEffect, useState } from 'react';
import {
    Card,
    Grid,
    TextField,
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    FormGroup,
    MenuItem,
    InputLabel,
    Select
} from '@mui/material';
import classes from "./index.module.scss"
import CommonField from './CommonField';
function MotorMannerisms({ questions, patientName, setQuestionData, isError, setIsError,setIsDisableContinue }: any) {
    let QuestionNumber = questions.personalDetails.length + questions.familyHistory.length + questions.communication.length + 
    questions.socialInteraction.length + questions.playImagination.length + questions.sensory.length

    const handleAnswerChange = (index: number, value: any, key: string) => {
        const validAnswers = ["Parent", "Carer", "Guardian"];
        let tempQuestions = { ...questions };
        let tempPersonDetail = [...tempQuestions.motorMannerisms];
        tempPersonDetail[index].question = tempPersonDetail[index].question.replace('[forename]', patientName);
        tempPersonDetail[index].answer = value;
     
        setQuestionData({ ...tempQuestions, motorMannerisms: tempPersonDetail });
    };




    useEffect(() => {
        setIsError(false)
    }, [])

    return (
        <>
            <Grid container direction="column" spacing={2} className={classes.Referral}>
                {questions?.motorMannerisms && questions?.motorMannerisms?.map((ques: any, index: number) => (
                    <>
                        {/* {handleRenderComponent(ques.type, index, ques.question, ques.answer, ques.option, "parent_carers_detail", ques.isRequired)} */}
                        {<CommonField
                            type={ques.type}
                            index={index}
                            question={ques.question.replace('[forename]',patientName)}
                            answer={ques.answer}
                            options={ques.option}
                            key={"motorMannerisms"}
                            required={ques.isRequired}
                            question_number={QuestionNumber}
                            questions={questions?.motorMannerisms}
                            isError={isError}
                            handleAnswerChange={handleAnswerChange}
                            ques={ques}
                            setIsDisableContinue={setIsDisableContinue}
                            totalQuestion={questions?.motorMannerisms?.length}
                        />}

                        {/* {isError && ques.answer === "" && ques.isRequired && <p className={classes.errorColor}>This field is required</p>} */}
                    </>
                ))}
            </Grid>
        </>
    );
}

export default MotorMannerisms;
