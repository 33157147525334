export const openURLQuestion: any = {
  consent: [
    {
      question_id: "ques1",
      placeholderText: "",
      objectIndex: 0,
      AdditinalInfo:
        "We cannot accept any Requests for Support without consent from the parent/carer/legal guardian or young person (where appropriate).",
      showAdditinalInfo: false,
      question: "Has the parent or carer provided consent for this referral?",
      answer: "",
      type: "radio",
      option: ["Yes", "No", "Not required: young person 16+"],
      isRequired: true,

      isCompulsory: true,
    },
    {
      question_id: "ques2",
      placeholderText: "",
      AdditinalInfo: "",
      showAdditinalInfo: false,
      objectIndex: 1,
      question:
        "Has the referral been discussed with the child or young person?",
      answer: "",
      type: "radio",
      option: ["Yes", "No"],
      isRequired: true,

      isCompulsory: true,
    },
    {
      question_id: "ques3",
      placeholderText:
        "Please comment on the reason the child/ young person is not consenting to this referral (e.g. not at an age where consent can be made, non-verbal, not gillick competent)",
      AdditinalInfo: "",
      showAdditinalInfo: false,
      objectIndex: 2,
      question:
        "Please give reasons as to why the young person has not consented to this Request for Support:",
      answer: "",
      type: "textArea",
      option: "",
      isRequired: false,

      isCompulsory: true,
    },
    {
      question_id: "ques4",

      placeholderText: "",
      AdditinalInfo:
        "We will not be able to provide appropriate support without collaborating and sharing information with other agencies/educational setting. The referral will be rejected on these grounds.",
      showAdditinalInfo: false,
      objectIndex: 3,
      question:
        " Is there parental consent for sharing of information/onward referral to other agencies/educational settings etc?",
      answer: "",
      type: "radio",
      option: ["Yes", "No"],
      isRequired: true,

      isCompulsory: true,
    },
    // {
    //   question_id: 'ques5',
    //   placeholderText:
    //     'Please comment on the reason for consent for information sharing not being provided as this will impact considerably on the quality of support our service can provide.',
    //   AdditinalInfo: '',
    //   showAdditinalInfo: false,
    //   objectIndex: 4,
    //   question:
    //     'Please give reasons as to why the parent / carer or young person has not consented to this Request for Information Sharing:',
    //   answer: '',
    //   type: 'textArea',
    //   option: ' ',
    //   isRequired: false,

    //   isCompulsory: true,
    // },
  ],

  reason: [
    {
      question_id: "ques6",
      question: "Reason for referral",
      placeholderText: "",
      AdditinalInfo:
        "Please note that the type of assessment carried out might vary as we collect more information about the child or young person which might alter the presentation of need. E.g. we might carry out an ADHD assessment alongside an Autism assessment for a child or young person who was originally referred for an Autism assessment only. ",
      showAdditinalInfo: true,
      objectIndex: 0,

      answer: "",
      type: "radio",
      isRequired: true,

      isCompulsory: true,
      option: [
        "Neurodevelopmental delay or disorder of function requiring an Autism assessment",
        "Neurodevelopmental delay or disorder of function requiring an ADHD Assessment",
        "Neurodevelopmental delay or disorder of function potentially requiring both an Autism and ADHD Assessment",
      ],
    },
    {
      question_id: "ques7",
      placeholderText:
        "Please state nature of difficulties providing an explanation of concerns including specific functional, sensory, social, communication and motor difficulties. Highlight any risks you are aware of",
      AdditinalInfo: "",
      showAdditinalInfo: false,
      objectIndex: 1,
      question: "Reason for Request for Support",
      answer: "",
      type: "textArea",
      option: "",
      isRequired: true,
      isCompulsory: true,
    },
  ],

  person_Detail: [
    {
      question_id: "ques8",
      placeholderText: "Enter Child/Young Person's First Name",
      AdditinalInfo: "",
      showAdditinalInfo: false,
      objectIndex: 0,
      question: "Child/Young Person's First Name",
      answer: "",
      type: "text",

      option: "",
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: "ques9",

      placeholderText: "Enter Child/Young Person's Surname",
      AdditinalInfo: "",
      showAdditinalInfo: false,
      objectIndex: 1,
      question: "Child/Young Person's Surname",
      answer: "",
      type: "text",
      option: "",
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: "ques10",

      placeholderText: "",
      AdditinalInfo:
        "We do not diagnose ADHD below 6 years of age - Please refer to the Health Visiting service for a developmental screen and behavioural support in the first instance. We will be happy to receive a re-referral if the Health Visitor escalates concerns.",
      showAdditinalInfo: false,
      objectIndex: 2,
      question: "Child/Young Person's Date of Birth",
      answer: "",
      type: "date",
      option: "",
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: "ques11",

      placeholderText: "",
      AdditinalInfo: "",
      showAdditinalInfo: false,
      objectIndex: 3,
      question:
        "Are there any concerns around the child's/young person's physical development or motor co-ordination?",
      answer: "",
      type: "radio",
      option: ["Yes", "No"],
      isRequired: true,
      isCompulsory: true,
    },
    // {
    //   question_id: 'ques12',
    //
    //   placeholderText: '',
    //   AdditinalInfo:
    //     'Your response indicates that there might be a regression in motor skills. Please strongly consider advising the family to seek a referral from the GP to their local Acute Paediatric service',
    //   showAdditinalInfo: false,
    //   objectIndex: 4,
    //   question:
    //     'Was there a time when the child/young person was physically more active than they currently are?',
    //   answer: '',
    //   type: 'radio',
    //   option: ['Yes', 'No'],
    //   isRequired: true,
    // },
    {
      question_id: "ques12",

      placeholderText: "",
      AdditinalInfo:
        "Your response indicates that there might be a regression in motor skills. Please strongly consider advising the family to seek a referral from the GP to their local Acute Paediatric service",
      showAdditinalInfo: false,
      objectIndex: 4,
      question: "Has a regression in motor skills been reported or noted?",
      answer: "",
      type: "radio",
      option: ["Yes", "No"],
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: "ques13",

      placeholderText:
        "Tell us who they communicate with, how they communicate, why they communicate – for example: to express their needs, to give information, to share experiences, to have a to and from conversation, or ‘no concerns’. ",
      AdditinalInfo: "",
      showAdditinalInfo: false,
      objectIndex: 5,
      question: "Please describe the child's/young person's communication",
      answer: "",
      type: "textArea",
      option: "",
      isRequired: true,
      isCompulsory: true,
    },
    // {
    //   question_id: 'ques14',
    //
    //   placeholderText: '',
    //   AdditinalInfo:
    //     'We cannot accept this referral in line with NICE guidelines',
    //   showAdditinalInfo: false,
    //   objectIndex: 6,
    //   question:
    //     'Was there a time when the child/young person communicated or socialised more or better than they currently do?',
    //   answer: '',
    //   type: 'radio',
    //   option: ['Yes', 'No'],
    //   isRequired: true,
    // },
    {
      question_id: "ques14",

      placeholderText: "",
      AdditinalInfo:
        "We cannot accept this referral in line with NICE guidelines",
      showAdditinalInfo: false,
      objectIndex: 6,
      question:
        "Has a regression in language or social skills been reported or noted?",
      answer: "",
      type: "radio",
      option: ["Yes", "No"],
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: "ques15",
      placeholderText: "",
      AdditinalInfo: "",
      showAdditinalInfo: false,
      objectIndex: 7,
      question:
        "Are there any concerns with the child or young person's mental health and wellbeing?",
      answer: "",
      type: "radio",
      option: ["Yes", "No"],
      isRequired: false,
      isCompulsory: true,
    },
    {
      question_id: "ques16",

      placeholderText: "",
      AdditinalInfo:
        `<p>Your referral will be screened by one of our clinicians to decide next steps. Liaison with the family's local CAMHS service might be needed before progressing further. It is worth noting that if  CAMHS practitioners feel that there is an underlying presentation of Autism and/or ADHD they can approach our paediatric team directly - it is worth exploring with the family whether they want to discuss this with their local CAMHS service before progressing with this referral. The family can seek advice from their local CAMHS service by contacting them on <a href="tel:08009530222">08009530222</a> or email <a href="mailto:SET-CAMHS.referrals@nelft.nhs.uk">SET-CAMHS.referrals@nelft.nhs.uk</a>.</p>`,
      showAdditinalInfo: false,
      objectIndex: 8,
      question:
        "Is the child or young person receiving CAMHS support for any of the following conditions?",
      answer: "",
      type: "radio",
      option: [
        "Depression",
        "Severe Trauma",
        "Severe Anxiety (e.g. refusing to go to school)",
        "Severe Eating Disorder",
        "Psychotic Behaviour",
        "Not sure",
        "No",
      ],
      isRequired: false,
      isCompulsory: true,
    },
    {
      question_id: "ques67",

      placeholderText: "",
      AdditinalInfo: "",
      showAdditinalInfo: false,
      objectIndex: 9,
      question:
        "Are there any other health conditions or diagnoses that we need to be aware of? ",
      answer: "",
      type: "textArea",
      option: "",
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: "ques17",

      placeholderText: "Enter Child/Young Person's NHS Number",
      AdditinalInfo: "",
      showAdditinalInfo: false,
      objectIndex: 10,
      question: "Child/Young Person's NHS Number?",
      answer: "",
      type: "text",
      option: "",
      isRequired: true,
      isCompulsory: false,
    },
    {
      question_id: "ques18",

      placeholderText: "Please Select",
      AdditinalInfo: "",
      showAdditinalInfo: false,
      objectIndex: 11,
      question: "Child/Young Person's Gender?",
      answer: "",
      type: "dropdown",
      option: ["Male", "Female", "Gender Diverse", "Prefer not to say"],
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: "ques19",

      placeholderText: "Enter Parent/Carer/Young Person's Telephone/Mobile",
      AdditinalInfo: "",
      showAdditinalInfo: false,
      objectIndex: 12,
      question: "Parent/Carer phone number",
      answer: "",
      type: "number",
      option: "",
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: "ques20",

      placeholderText: "Enter Child/Young Person's Address",
      AdditinalInfo: "",
      showAdditinalInfo: false,
      objectIndex: 13,
      question: "Child/Young person’s main address",
      answer: "",
      type: "text",
      option: "",
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: "ques21",

      placeholderText: "Enter Child/Young Person's Postcode",
      AdditinalInfo: "",
      showAdditinalInfo: false,
      objectIndex: 14,
      question: "Child/Young Person's Postcode?",
      answer: "",
      type: "text",
      option: "",
      isRequired: true,
      isCompulsory: true,
    },
    // {
    //   question_id: 'ques22',
    //   question_number: 22,
    //   question: "Child/Young Person's Email?",
    //   answer: '',
    //   type: 'email',
    //   option: '',
    //   isRequired: false,
    // },
    {
      question_id: "ques66",

      placeholderText: "",
      AdditinalInfo: "",
      showAdditinalInfo: false,
      objectIndex: 15,
      question: "Select Child/Young Person's educational setting type?",
      answer: "",
      type: "radio",
      option: [
        "Elective Home Education / Not Currently in Formal Education",
        "Nursery/School/College",
        "Preschool",
      ],
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: "ques22",

      placeholderText: "Enter Child/Young Person School/Nursery/College (Enter atleast 3 characters)",
      AdditinalInfo: "To avoid any Information governance incidents, Please make sure that you select or enter the correct child’s school information, so that the child’s information will go to the correct school assigned to them. If you are not confident about the school details, rather than selecting the school via search process, use manual entry process to provide school information.",
      showAdditinalInfo: true,
      objectIndex: 16,
      question: "Select Child/Young Person's Nursery / School / College",
      answer: "",
      type: "dropdown",
      option: [],
      isRequired: false,
      isCompulsory: true,
    },
    {
      question_id: "ques64", //question add after some time

      placeholderText: "",
      AdditinalInfo: "",
      showAdditinalInfo: false,
      objectIndex: 17,
      question: "Enter School Name?",
      answer: "",
      type: "text",
      option: "",
      isRequired: false,
      isCompulsory: true,
    },
    {
      question_id: "ques65", //question add after some time

      placeholderText: "",
      AdditinalInfo: "",
      showAdditinalInfo: false,
      objectIndex: 18,
      question: "Enter School Address?",
      answer: "",
      type: "text",
      option: "",
      isRequired: false,
      isCompulsory: true,
    },
    {
      question_id: "ques23",
      question: "Does the Child/Young Person have a Disability?",
      placeholderText: "",
      answer: "",
      AdditinalInfo: "",
      showAdditinalInfo: false,
      objectIndex: 19,

      type: "radio",
      option: ["Yes", "No"],
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: "ques24",

      placeholderText: "",
      objectIndex: 20,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: "Please describe disability?",
      answer: "",
      type: "textArea",
      option: "",
      isRequired: false,
      isCompulsory: true,
    },
    {
      question_id: "ques25",

      placeholderText: "Language used",
      objectIndex: 21,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: "Child/Young Person's Language?",
      answer: "",
      type: "text",
      option: "",
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: "ques26",
      placeholderText: "",
      objectIndex: 22,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: "Interpreter Required for Family?",
      answer: "",
      type: "radio",
      option: ["Yes", "No"],
      isRequired: true,

      isCompulsory: true,
    },
    {
      question_id: "ques28",
      placeholderText: "Please Select",
      objectIndex: 23,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: "Child/Young Person's Religion",
      answer: "",
      type: "dropdown",

      isCompulsory: true,
      option: [
        "Christianity",
        "Islam",
        "Hinduism",
        "Buddhism",
        "Sikhism",
        "Judaism",
        "Other",
        "none",
      ],
      isRequired: true,
    },
    {
      question_id: "ques27",

      placeholderText: "Please Select",
      objectIndex: 24,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: "Ethnicity",
      answer: "",
      isCompulsory: true,
      type: "dropdown",
      option: [
        "Asian or Asian British: Indian ",
        "Asian/Asian British:Pakistani",
        "Asian/Asian British: Bangladeshi",
        "Asian/Asian Brit: Chinese",
        "Other Asian background",
        "Black/African/Caribbn/Black Brit: Caribbean",
        "Black/Afri/Carib/Black Brit: African",
        "Black/Afr/Carib/Black Brit: other Black",
        "Mixed: White+Black Caribbean ",
        "Mixed: White+Black African",
        "Mixed: White+Asian",
        "Mixed: other Mixed/multiple backgrd",
        "White:Eng/Welsh/Scot/NI/Brit ",
        "White: Irish ",
        "White: Gypsy/Irish Traveller ",
        "Other white ethnic group",
      ],
      isRequired: true,
    },

    {
      question_id: "ques29",

      placeholderText: "Enter Child/Young Person's Nationality",
      objectIndex: 25,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: "Child/Young Person's Nationality?",
      answer: "",
      type: "text",
      option: "",
      isRequired: true,
      isCompulsory: true,
    },
  ],

  gp_detail: [
    {
      question_id: "ques30",

      placeholderText: "Enter Child/Young GP Name (Enter atleast 3 characters)",
      AdditinalInfo: "",
      showAdditinalInfo: false,
      objectIndex: 0,
      question: "Child/Young Person's GP Name",
      answer: "",
      type: "dropdown",
      option: "",
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: "ques31",

      placeholderText: "Enter Child/Young Person's GP Address",
      objectIndex: 1,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: "Child/Young Person's GP Address",
      answer: "",
      type: "text",
      option: "",
      isRequired: true,
      isCompulsory: true,
    },
  ],

  safe_guarding_detail: [
    {
      question_id: "ques32",

      placeholderText: "",
      objectIndex: 0,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: "Is this Child/Young person looked after?",
      answer: "",
      type: "radio",
      option: ["Yes", "No"],
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: "ques33",

      placeholderText: "",
      objectIndex: 1,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: "Is this a child in need or are there safeguarding concerns?",
      answer: "",
      type: "radio",
      option: ["Yes", "No"],
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: "ques34",
      placeholderText: "",

      objectIndex: 2,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: "Please describe concerns for Child/Young person?",
      answer: "",
      type: "textArea",
      option: "",
      isRequired: false,
      isCompulsory: true,
    },
    {
      question_id: "ques35",

      placeholderText: "",
      objectIndex: 3,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: "Is this child on a Protection Plan?",
      answer: "",
      type: "radio",
      option: ["Yes", "No"],
      isRequired: true,
      isCompulsory: true,
    },
  ],

  parent_carers_detail: [
    {
      question_id: "ques36",

      placeholderText: "Enter Parent/Carer's Name",
      objectIndex: 0,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: "Primary Parent/Carer's Name",
      answer: "",
      type: "text",
      option: "",
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: "ques37",

      placeholderText: "Please Select",
      objectIndex: 1,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: "Relationship to Child/Young Person",
      answer: "",
      type: "dropdown",
      option: ["Parent", "Guardian", "Carer", "Other"],
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: "ques38",

      placeholderText: "",
      objectIndex: 2,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: "Please describe relationship",
      answer: "",
      type: "textArea",
      option: "",
      isRequired: false,
      isCompulsory: true,
    },
    // {
    //   question_id: 'ques40',
    //   question_number: 40,
    //   question: 'Names of people with Parental Responsibility?',
    //   answer: '',
    //   type: 'text',
    //   option: '',
    //   isRequired: false,
    // },
    {
      question_id: "ques39",

      placeholderText:
        "Enter Parent/Carer's alternative address if different to Child/Young Person",
      objectIndex: 3,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question:
        "Parent/Carer's Address (edit if different to Child/Young Person)",
      answer: "",
      type: "text",
      option: "",
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: "ques40",
      placeholderText:
        "Enter Parent/Carer's alternative telephone/mobile number if different to Child/Young Person's",
      objectIndex: 4,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: "Parent/Carer's Telephone/Mobile (e.g if different from main)",
      answer: "",
      type: "number",
      option: "",
      isRequired: true,

      isCompulsory: true,
    },
    {
      question_id: "ques41",
      // isOptional: true,
      placeholderText:
        "Enter Parent/Carer's alternative telephone/mobile number if different to Child/Young Person's",
      objectIndex: 5,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: "Parent/Carer's Other Telephone/Mobile (e.g work)",
      answer: "",
      type: "number",
      option: "",
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: "ques42",

      placeholderText:
        "Enter Parent/Carer's alternative email if different to Child/Young Person",
      objectIndex: 6,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: "Parent/Carer's Email",
      answer: "",
      type: "email",
      option: "",
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: "ques43",

      placeholderText:
        "E.g. family composition, support network, other siblings with special needs or known to child health or other agencies etc.",
      objectIndex: 7,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question:
        "Are there any special family circumstances we should be aware of?",
      answer: "",
      type: "text",
      option: "",
      isRequired: true,
      isCompulsory: true,
    },
  ],

  additional_parent_carers_details: [
    {
      question_id: "ques44",

      placeholderText: "",
      objectIndex: 0,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question:
        "Do you want to provide access of the My Care Bridge Portal to the child/young person?",
      answer: "",
      type: "radio",
      option: ["Yes", "No"],
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: "ques45",

      placeholderText:
        "Enter Child/Young Person's Email (enter 'N/A' if contact via email is not desired)",
      objectIndex: 1,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: "Child/Young Person's Email?",
      answer: "",
      type: "email",
      option: "",
      isRequired: false,
      isCompulsory: true,
    },
    {
      question_id: "ques46",

      placeholderText: "",
      objectIndex: 2,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question:
        "Do you want to provide access to the parent/carer portal to additional parent or carer?",
      answer: "None",
      type: "radio",
      option: ["None", "Parent", "Carer"],
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: "ques47",

      placeholderText: "Enter Parent/Carer's Name",
      objectIndex: 3,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: "Parent/Carer's Name?",
      answer: "",
      type: "text",
      option: "",
      isRequired: false,
      isCompulsory: true,
    },
    {
      question_id: "ques48",

      placeholderText:
        "Enter Parent/Carer's alternative email if different to Child/Young Person",
      objectIndex: 4,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: "Parent/Carer Email?",
      answer: "",
      type: "email",
      option: "",
      isRequired: false,
      isCompulsory: true,
    },
    {
      question_id: "ques49",

      placeholderText:
        "Enter Parent/Carer's alternative address if different to Child/Young Person",
      objectIndex: 5,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question:
        "Parent/Carer's Address (Edit if different to Child/Young Person)?",
      answer: "",
      type: "textArea",
      option: "",
      isRequired: false,
      isCompulsory: true,
    },
    {
      question_id: "ques50",

      placeholderText:
        "Enter Parent/Carer's alternative telephone/mobile number if different to Child/Young Person's",
      objectIndex: 6,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: "Parent/Carer's Telephone/Mobile?",
      answer: "",
      type: "number",
      option: "",
      isRequired: false,
      isCompulsory: true,
    },
  ],

  referrer_detail: [
    {
      question_id: "ques51",

      placeholderText: "Enter Referrer's Name",
      objectIndex: 0,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: "Referrer's Name?",
      answer: "",
      type: "text",
      option: "",
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: "ques52",

      placeholderText:
        "Enter Job Title/Relationship to the Child or Young Person",
      objectIndex: 1,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: "Job Title / Relationship to the Child or Young Person?",
      answer: "",
      type: "text",
      option: "",
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: "ques53",

      placeholderText: "Enter Referrer's Telephone",
      objectIndex: 2,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: "Referrer's Telephone?",
      answer: "",
      type: "number",
      option: "",
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: "ques54",

      placeholderText: "Enter Referrer's Email",
      objectIndex: 3,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: " Referrer's Email?",
      answer: "",
      type: "email",
      option: "",
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: "ques55",

      placeholderText: "Enter Referrer's Address",
      objectIndex: 4,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: "Referrer's Address?",
      answer: "",
      type: "text",
      option: "",
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: "ques56",

      placeholderText: "Enter Referrer's Postcode",
      objectIndex: 5,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: "Referrer's Postcode?",
      answer: "",
      type: "text",
      option: "",
      isRequired: true,
      isCompulsory: true,
    },
  ],

  support_so_far: [
    {
      question_id: "ques57",

      placeholderText:
        "Please provide details of any interventions that have been tried so far (e.g. at school/preschool, from social care, charities, counselling etc). We expect that targeted support has been in place for at least 6 months' prior to referral being made. ",
      objectIndex: 0,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: "Support so far",
      answer: "",
      type: "textArea",
      option: "",
      isRequired: true,
      isCompulsory: true,
    },
    {
      question_id: "ques58",

      placeholderText: "",
      objectIndex: 1,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question:
        "Has an integrated review between the health visitor and the preschool setting been completed? (this would include completion of ASQ3 and ASQ:SE-2 forms)",
      answer: "",
      type: "radio",
      option: ["Yes", "No"],
      isRequired: false,
      isCompulsory: true,
    },
    {
      question_id: "ques59",

      placeholderText: "",
      objectIndex: 2,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question:
        "Have early support strategies which could include interventions using ASQ:SE-2 activity sheets been implemented for at least 6 months?",
      answer: "",
      type: "radio",
      option: ["Yes", "No", "Not Sure"],
      isRequired: false,
      isCompulsory: true,
    },
    {
      question_id: "ques60",

      placeholderText: "",
      objectIndex: 3,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question:
        "Has the ASQ:SE-2 been repeated after early support strategies were implemented?",
      answer: "",
      type: "radio",
      option: ["Yes", "No", "Not Sure"],
      isRequired: false,
      isCompulsory: true,
    },
    {
      question_id: "ques61",

      placeholderText: "Please Select",
      objectIndex: 4,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      isCompulsory: true,
      question:
        "Other Professional Involvement (select all that are applicable)",
      answer: "",
      type: "dropdown",
      option: [
        { label: "Health Visitor", value: "Health Visitor" },
        { label: "Education Nurse", value: "Education Nurse" },
        { label: "Nursery", value: "Nursery" },
        { label: "SENCo", value: "SENCo" },
        { label: "Early Intervention", value: "Early Intervention" },
        {
          label: "Educational Psychologist",
          value: "Educational Psychological",
        },
        { label: "CAMHS", value: "CAMHS" },
        {
          label: "Hospital/Community Doctor",
          value: "Hospital/Community Doctor",
        },
        { label: "Social Worker", value: "Social Worker" },
        {
          value: "Educational Welfare Officer",
          label: "Educational Welfare Officer",
        },
        { value: "Youth Offending Service", label: "Youth Offending Service" },
        { label: "Other", value: "Other" },
      ],
      isRequired: true,
    },
    {
      question_id: "ques62",

      placeholderText: "Please name any other relevant professional involved",
      objectIndex: 5,
      AdditinalInfo: "",
      showAdditinalInfo: false,
      question: "Other Professional Involvement",
      answer: "",
      type: "text",
      option: "",
      isRequired: false,
      isCompulsory: true,
    },
  ],
  digital_literacy: [
    {
      question_id: "ques63",

      placeholderText: "",
      AdditinalInfo: "The family has no access to an electronic device.",
      showAdditinalInfo: false,
      objectIndex: 0,
      question:
        "Does this family have access to an electronic device (e.g. smartphone, laptop, tablet) and the internet so that they can submit further information to the MyCareBridge parent portal? (this could include supported access via school, library, etc).",
      answer: "",
      type: "radio",
      option: ["Yes", "No"],
      isRequired: true,
      isCompulsory: true,
    },
  ],
};
