import React, { useEffect, useState } from 'react';
import {
  Card,
  Grid,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormGroup,
  MenuItem,
  InputLabel,
  Select
} from '@mui/material';
import classes from "./index.module.scss"
import CommonField from './CommonField';

function AttentionLevels({ questions, setQuestionData, isError, setIsError, setIsDisableContinue, patientName }: any) {
  let QuestionNumber = questions.personalDetail.length + questions.communication.length +
    questions.socialInteraction.length + questions.creativityImagination.length
    + questions.behaviour.length + questions.sensory.length


  const handleAnswerChange = (index: number, value: any, key: string, updatedQuestion: string, updatedAdditionalInfo: string, updatedAdditionalQuestion: string, updatedIsHeading: string) => {
    let tempQuestions = { ...questions };
    let tempAttentionLevel = [...tempQuestions.attentionActivityLevels];
    tempAttentionLevel[index].answer = value;

    const question_id = tempAttentionLevel[index].question_id;

    if (tempAttentionLevel[index]) {
      if (tempAttentionLevel[index].question) {
        tempAttentionLevel[index].question = updatedQuestion
      }
      if (tempAttentionLevel[index].additional_info) {
        tempAttentionLevel[index].additional_info = updatedAdditionalInfo
      }
      if (tempAttentionLevel[index].isAdditionalStructure && tempAttentionLevel[index].isAdditionalStructure.question) {
        tempAttentionLevel[index].isAdditionalStructure.question = updatedAdditionalQuestion
      }
      if (tempAttentionLevel[index].isHeadingLine && tempAttentionLevel[index].isHeading) {
        tempAttentionLevel[index].isHeading = updatedIsHeading
      }
    }

    if (["ques64", "ques65", "ques66"].includes(question_id) && value === 'No') {
      tempAttentionLevel[index].isAdditionalStructure.isRequired = false;
      tempAttentionLevel[index].isAdditionalStructure.answer = '';
    } else if (["ques64", "ques65", "ques66"].includes(question_id) && value === 'Yes') {
      tempAttentionLevel[index].isAdditionalStructure.isRequired = true;
      tempAttentionLevel[index].isAdditionalStructure.answer = '';
    }
    setQuestionData({ ...tempQuestions, attentionActivityLevels: tempAttentionLevel });
  };

  const handleAdditionalChange = (index: number, value: any) => {
    let tempQuestions = { ...questions };
    let tempOtherInformation = [...tempQuestions.attentionActivityLevels];
    tempOtherInformation[index].isAdditionalStructure.answer = value;
    setQuestionData({ ...tempQuestions, attentionActivityLevels: tempOtherInformation });
  }

  const handleNestedAnswerChange = (ind: any, index: number, value: any, fileType: any) => {
    let tempQuestions = { ...questions };
    let tempOtherInformation = [...tempQuestions.attentionActivityLevels];
    const question_id = tempOtherInformation[index].isNestedStructure[ind].question_id;
    if (tempOtherInformation[index].isNested === true) {
      tempOtherInformation[index].isNestedStructure[ind].answer = value;
      if (["ques1"].includes(question_id) && value === 'Below Average') {
        tempOtherInformation[index].isNestedStructure[ind + 1].answer = "";
        tempOtherInformation[index].isNestedStructure[ind + 1].isRequired = true;
        tempOtherInformation[index].isNestedStructure[ind + 1].question = "Bottom";
      } else if (["ques1"].includes(question_id) && value === 'Above Average') {
        tempOtherInformation[index].isNestedStructure[ind + 1].answer = "";
        tempOtherInformation[index].isNestedStructure[ind + 1].isRequired = true;
        tempOtherInformation[index].isNestedStructure[ind + 1].question = "Top";
      } else if (["ques1"].includes(question_id) && value === 'Average') {
        tempOtherInformation[index].isNestedStructure[ind + 1].isRequired = false;
        tempOtherInformation[index].isNestedStructure[ind + 1].question = "";
        tempOtherInformation[index].isNestedStructure[ind + 1].answer = "";
      }
      setQuestionData({ ...tempQuestions, attentionActivityLevels: tempOtherInformation });
    }
  }

  useEffect(() => {
    setIsError(false)
  }, [])

  return (
    <>
      <Grid container direction="column" spacing={2} className={classes.Report}>
        {questions?.attentionActivityLevels && questions?.attentionActivityLevels?.map((ques: any, index: number) => (
          <>

            {/* {handleRenderComponent(ques.type, index, ques.question, ques.answer, ques.option, "parent_carers_detail", ques.isRequired)} */}
            {<CommonField
              type={ques.type}
              index={index}
              question={ques.question}
              answer={ques.answer}
              options={ques.option}
              fileType={"attentionActivityLevels"}
              required={ques.isRequired}
              question_number={QuestionNumber}
              questions={questions?.attentionActivityLevels}
              isError={isError}
              handleAnswerChange={handleAnswerChange}
              ques={ques}
              setIsDisableContinue={setIsDisableContinue}
              totalQuestion={questions?.attentionActivityLevels?.length}
              isAdditional={ques?.isAdditional}
              isAdditionalQuestion={ques?.isAdditionalStructure}
              handleAdditionalChange={handleAdditionalChange}
              patientName={patientName}
              isHeading={ques?.isHeading}
              isHeadingLine={ques?.isHeadingLine}
              isNested={ques?.isNested}
              isNestedStructure={ques?.isNestedStructure}
              handleNestedAnswerChange={handleNestedAnswerChange}
            />}

            {/* {isError && ques.answer === "" && ques.isRequired && <p className={classes.errorColor}>This field is required</p>} */}
          </>
        ))}
      </Grid>
    </>
  );
}

export default AttentionLevels;
