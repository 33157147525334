import React, { Suspense, memo, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Menu,
  ListItemIcon,
  Typography,
  Tooltip,
} from "@mui/material";
import userCss from "./index.module.scss";
import {
  AddCircle as AddCircleIcon,
  MoreHoriz as MoreHorizIcon,
  Person as PersonIcon,
  PersonOff as PersonOffIcon,
  Visibility as VisibilityIcon,
  Delete as DeleteIcon,
  MarkEmailRead as MarkEmailReadIcon,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ResetPagination,
  changeTab,
  deleteUserByID,
  getUserList,
  reActivationbyId,
  statusChangedbyId,
} from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import CONSTANT from "../../../constants/constant";
import moment from "moment";
import Search from "../../../components/atoms/Search";
import classes from "./index.module.scss";
import Table from "../../../components/atoms/Table";
import constant from "../../../constants/constant";
import ConfirmationModal from "../../../components/atoms/ConfirmationModal";
import { useResponsive } from "../../../components/atoms/useResponsive";
import { useClearSearch } from "../../../components/atoms/Hooks/useClearSearch";

const Export = React.lazy(() => import("../../../components/atoms/Export"));
const Listing = () => {
  const location = useLocation();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const pathParts = location.pathname.split("/").filter(Boolean);
  const param = pathParts[1];
  const [page, setPage] = useState(1);
  const { setSearch, search, clearSearch } = useClearSearch();
  const [userListing, setUserListing] = useState([]);
  const [isCreateCaseload, setIsCreateCaseload] = useState(false);
  const [isResendEmail, setisResendEmail] = useState(false);
  const [isSelectedStatus, setIsSelectedStatus] = useState("");
  const [limit, setLimit] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [columns, setColumns] = useState([]);
  const { windowWidth, isMediumScreen } = useResponsive();
  const tableColumns: any = {
    clinician: [
      {
        name: "#",
        cell: (row: any, index: number) =>
          ((page - 1) * limit + index + 1).toString().padStart(2, "0"),
        width: "50px",
        tblName: "",
      },
      {
        name: "Name",
        cell: (item: any, ind: number) => (
          <Tooltip title={item?.name} className={classes.text_wrap}>
            <span>{item?.name}</span>
          </Tooltip>
        ),
        selector: (item: any, ind: number) => item?.first_name,
        draggable: false,
        sortable: true,
        width: "20%",
        tblName: "first_name",
      },
      {
        name: "Email",
        cell: (item: any, ind: number) => (
          <Tooltip title={item?.email} className={classes.text_wrap}>
            <span>{item?.email}</span>
          </Tooltip>
        ),
        selector: (item: any, ind: number) => item?.email,
        sortable: true,
        width: "20%",
        tblName: "email",
      },
      {
        name: "Job Role",
        width: "12%",
        cell: (item: any, ind: number) => <span>{item?.jobRole}</span>,
        sortable: false,
        tblName: "jobRole",
      },
      {
        name: "Registered on",
        width: "12%",
        cell: (item: any, ind: number) => (
          <span>
            {item?.createdAt === null
              ? "-"
              : moment(item?.createdAt).format("DD/MM/YYYY")}
          </span>
        ),
        sortable: false,
        tblName: "createdAt",
      },
      {
        name: "Last Logged In",
        width: "13%",
        cell: (item: any, ind: number) => (
          <span>
            {item?.lastLoggedIn === null
              ? "-"
              : moment(item?.lastLoggedIn).format("DD/MM/YYYY , h:mm a")}
          </span>
        ),
        sortable: false,
        tblName: "createdAt",
      },
      {
        name: "Account Status",
        width: "13%",
        cell: (item: any, ind: number) => <span>{item?.status}</span>,
        sortable: false,
        tblName: "status",
      },
      {
        name: "Action",
        cell: (item: any, ind: number) => <span>{item?.action}</span>,
        width: "80px",
        sortable: false,
        tblName: "action",
      },
    ],
    admin: [
      {
        name: "#",
        cell: (row: any, index: number) =>
          ((page - 1) * limit + index + 1).toString().padStart(2, "0"),
        width: "50px",
        tblName: "",
      },
      {
        name: "Name",
        cell: (item: any, ind: number) => (
          <Tooltip title={item?.name} className={classes.text_wrap}>
            <span>{item?.name}</span>
          </Tooltip>
        ),
        selector: (item: any, ind: number) => item?.first_name,
        draggable: false,
        sortable: true,
        width: "20%",
        tblName: "first_name",
      },
      {
        name: "Email",
        cell: (item: any, ind: number) => (
          <Tooltip title={item?.email} className={classes.text_wrap}>
            <span>{item?.email}</span>
          </Tooltip>
        ),
        selector: (item: any, ind: number) => item?.email,
        sortable: true,
        width: "20%",
        tblName: "email",
      },
      {
        name: "Registered on",
        cell: (item: any, ind: number) => (
          <span>
            {item?.createdAt === null
              ? "-"
              : moment(item?.createdAt).format("DD/MM/YYYY")}
          </span>
        ),
        sortable: false,
        width: "15%",
        tblName: "createdAt",
      },
      {
        name: "Last Logged In",
        width: "15%",
        cell: (item: any, ind: number) => (
          <span>
            {item?.lastLoggedIn === null
              ? "-"
              : moment(item?.lastLoggedIn).format("DD/MM/YYYY , h:mm a")}
          </span>
        ),
        sortable: false,
        tblName: "createdAt",
      },
      {
        name: "Account Status",
        cell: (item: any, ind: number) => <span>{item?.status}</span>,
        sortable: false,
        width: "15%",
        tblName: "status",
      },
      {
        name: "Action",
        width: "80px",
        cell: (item: any, ind: number) => <span>{item?.action}</span>,
        sortable: false,
        tblName: "action",
      },
    ],
    parent: [
      {
        name: "#",
        cell: (row: any, index: number) =>
          ((page - 1) * limit + index + 1).toString().padStart(2, "0"),
        width: "50px",
        tblName: "",
      },
      {
        name: "Name",
        cell: (item: any, ind: number) => (
          <Tooltip title={item?.name} className={classes.text_wrap}>
            <span>{item?.name}</span>
          </Tooltip>
        ),
        selector: (item: any, ind: number) => item?.first_name,
        draggable: false,
        sortable: true,
        width: "150px",
        tblName: "first_name",
      },
      {
        name: "Email",
        cell: (item: any, ind: number) => (
          <Tooltip title={item?.email} className={classes.text_wrap}>
            <span>{item?.email}</span>
          </Tooltip>
        ),
        selector: (item: any, ind: number) => item?.email,
        sortable: true,
        width: "250px",
        tblName: "email",
      },

      {
        name: "Registered on",
        cell: (item: any, ind: number) => (
          <span>
            {item?.createdAt === null
              ? "-"
              : moment(item?.createdAt).format("DD/MM/YYYY")}
          </span>
        ),
        sortable: false,
        width: "100px",
        tblName: "createdAt",
      },
      {
        name: "Last Logged In",
        width: "150px",
        cell: (item: any, ind: number) => (
          <span>
            {item?.lastLoggedIn === null
              ? "-"
              : moment(item?.lastLoggedIn).format("DD/MM/YYYY , h:mm a")}
          </span>
        ),
        sortable: false,
        tblName: "createdAt",
      },
      {
        name: "Account Status",
        cell: (item: any, ind: number) => <span>{item?.status}</span>,
        sortable: false,
        width: "200px",
        tblName: "status",
      },
      {
        name: "Action",
        width: "80px",
        cell: (item: any, ind: number) => <span>{item?.action}</span>,
        sortable: false,
        tblName: "action",
      },
    ],
    education_setting: [
      {
        name: "#",
        cell: (row: any, index: number) =>
          ((page - 1) * limit + index + 1).toString().padStart(2, "0"),
        width: "50px",
        tblName: "",
      },
      {
        name: "Education Name",
        cell: (item: any, ind: number) => (
          <Tooltip title={item?.school_name} className={classes.text_wrap}>
            <span>{item?.school_name}</span>
          </Tooltip>
        ),
        selector: (item: any, ind: number) => item?.school_name,
        draggable: false,
        sortable: true,
        width: "170px",
        tblName: "school_name",
      },
      {
        name: "Name",
        cell: (item: any, ind: number) => (
          <Tooltip title={item?.name} className={classes.text_wrap}>
            <span>{item?.name}</span>
          </Tooltip>
        ),
        selector: (item: any, ind: number) => item?.first_name,
        draggable: false,
        sortable: true,
        width: "13%",
        tblName: "first_name",
      },
      {
        name: "Email",
        cell: (item: any, ind: number) => (
          <Tooltip title={item?.email} className={classes.text_wrap}>
            <span>{item?.email}</span>
          </Tooltip>
        ),
        selector: (item: any, ind: number) => item?.email,
        sortable: true,
        width: "15%",
        tblName: "email",
      },
      {
        name: "phone",
        cell: (item: any, ind: number) => item?.phone_no,
        selector: (item: any, ind: number) => item?.phone_no,
        sortable: true,
        width: "150px",
        tblName: "phone_no",
      },
      {
        name: "Postcode",
        cell: (item: any, ind: number) =>
          item?.postCode === "" ? "-" : item?.postCode,
        width: "100px",
        tblName: "postCode",
      },
      {
        name: "Registered on",
        cell: (item: any, ind: number) => (
          <span>
            {item?.createdAt === null
              ? "-"
              : moment(item?.createdAt).format("DD/MM/YYYY")}
          </span>
        ),
        sortable: false,
        width: "120px",
        tblName: "createdAt",
      },
      {
        name: "Last Logged In",
        width: "180px",
        cell: (item: any, ind: number) => (
          <span>
            {item?.lastLoggedIn === null
              ? "-"
              : moment(item?.lastLoggedIn).format("DD/MM/YYYY , h:mm a")}
          </span>
        ),
        sortable: false,
        tblName: "createdAt",
      },
      {
        name: "Account Status",
        cell: (item: any, ind: number) => <span>{item?.status}</span>,
        sortable: false,
        width: "140px",
        tblName: "status",
      },
      {
        name: "Action",
        width: "80px",
        cell: (item: any, ind: number) => <span>{item?.action}</span>,
        sortable: false,
        justifyContent: "end",
        tblName: "action",
      },
    ],
  };
  const scopeMap: any = {
    admin: "Add Admin",
    clinician: "Add Clinician",
    education_setting: "Add Education Setting",
    parent: "Add Parent",
  };
  const getScope: any = {
    admin: CONSTANT.SCOPE.CLIENT_ADMIN,
    clinician: CONSTANT.SCOPE.CLINICIAN,
    education_setting: CONSTANT.SCOPE.SCHOOL,
    parent: CONSTANT.SCOPE.PARENT,
  };
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const userReducer = (state: any) => state?.userManagementReducer;
  const addUrlMap: any = {
    admin: "/user-management/admin/add",
    clinician: "/user-management/clinician/add",
    parent: "/user-management/parent/add",
    education_setting: "/user-management/education_setting/add",
  };

  const addUrl = addUrlMap[param] || "";

  const userData = createSelector(userReducer, (state) => ({
    userList: state.userData,
  }));
  const { userList } = useSelector(userData);

  useEffect(() => {
    const scopeType: any = {
      admin: "Admin Accounts",
      clinician: "Clinician Accounts",
      education_setting: "Education Setting Accounts",
      parent: "Parent Accounts",
    };

    const scope: any = getScope[param];
    const addColumn: any = tableColumns[param] || [];
    setColumns(addColumn);
    if (scope) {
      let role =
        scope === constant.SCOPE.CLIENT_ADMIN
          ? constant.ROLE.RESTRICTED_ACCESS
          : null;
      dispatch(getUserList({ scope, page, search, limit, role }));
      if (scope !== constant.SCOPE.CLIENT_ADMIN) {
        dispatch(changeTab(scopeType[param]));
      }
    }
    dispatch(ResetPagination(true)); 
  }, [param, page, search, limit]);

  const handleClick = (event: any, item: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedUserId(item?.id);
    setIsSelectedStatus(item?.status);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = () => {
    const urlMap: any = {
      admin: `/user-management/admin/edit/${selectedUserId}`,
      clinician: `/user-management/clinician/edit/${selectedUserId}`,
      parent: `/user-management/parent/edit/${selectedUserId}`,
      education_setting: `/user-management/education_setting/edit/${selectedUserId}`,
    };
    const url: any = urlMap[param] || "";
    Navigate(url);
  };

  useEffect(() => {
    if (
      userList?.user?.length !== 0 &&
      getScope[param] === (userList?.user && userList?.user[0]?.scope)
    ) {
      if (param === "admin") {
        let loginDetailString = localStorage.getItem("loginResponse");
        let loginDetail = loginDetailString
          ? JSON.parse(loginDetailString)
          : {};
        const UserArray = userList?.user?.filter(
          (obj: any) => obj?.id !== loginDetail.id
        );
        const filterArray = UserArray?.filter(
          (obj: any) => obj?.role !== constant.ROLE.ALL_ACCESS
        );
        setUserListing(filterArray);
      } else {
        setUserListing(userList?.user);
      }
    } else {
      setUserListing([]);
      setPage(1);
    }
  }, [userList]);

  const resendActivation = () => {
    dispatch(reActivationbyId(selectedUserId));
    setAnchorEl(null);
    setisResendEmail(false);
  };

  const onChanageStatus = (status: string) => {
    const payload = { status };
    const scope = getScope[param];
    let role = null;
    if (scope === constant.SCOPE.CLIENT_ADMIN) {
      role = constant.ROLE.RESTRICTED_ACCESS;
    }
    const forListing = { scope, page, search, limit, role };

    dispatch(statusChangedbyId(selectedUserId, payload, forListing));

    setAnchorEl(null);
  };

  const handleDeleteUser = (id: any) => {
    const scope: any = getScope[param];
    let role = null;

    if (scope === constant.SCOPE.CLIENT_ADMIN) {
      role = constant.ROLE.RESTRICTED_ACCESS;
    }

    const forListing = { scope, page, search, limit, role };

    dispatch(deleteUserByID(id, forListing));

    setAnchorEl(null);
    setIsCreateCaseload(false);
  };

  useEffect(() => {
    if (param && 0) {
      setPage(1);
    }
    clearSearch();
  }, [param]);

  const tableWidth = () => {
    const sidebarWidth = !isMediumScreen ? 0 : 270;
    const adjustedWidth = windowWidth - sidebarWidth;
    return `calc(${adjustedWidth}px)`;
  };

  return (
    <>
      <Box className={userCss.innerheader}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          className="forMobilecss"
        >
          <Grid item xs={6} className={userCss.searchInputbx}>
            <Search
              searchCallBack={(e: string) => setSearch(e)}
              searchValue={search}
            />
          </Grid>
          <Grid item xs={6} display={"flex"} gap={2} justifyContent={"end"}>
            <Suspense fallback={<div>Loading...</div>}>
              <Export data={userListing} columns={columns}></Export>
            </Suspense>

            {param !== "parent" && (
              <Button
                className={userCss.btnStyle}
                onClick={() => Navigate(addUrl)}
                size="large"
                variant="contained"
                sx={{ borderRadius: "30px" }}
                startIcon={<AddCircleIcon />}
              >
                {scopeMap[param]}
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>

      <Grid className={userCss.tableWrapper} style={{ maxWidth: tableWidth() }}>
        <Table
          columns={columns}
          className={"userLisingTable"}
          data={
            userListing &&
            userListing?.map((item: any, index: number) => {
              return {
                ...item,
                name: item?.first_name + " " + item?.last_name,
                status:
                  item?.status === CONSTANT.STATUS.ACTIVE ? (
                    <Typography className="activeStatus">Active</Typography>
                  ) : item?.status === CONSTANT.STATUS.INACTIVE ? (
                    <Typography className="deactiveStatus">Inactive</Typography>
                  ) : item?.status === CONSTANT.STATUS.NOT_REGISTERED ? (
                    <Typography className="notRegistered">
                      Not Registered
                    </Typography>
                  ) : (
                    ""
                  ),

                action: (
                  <div key={index}>
                    <IconButton
                      aria-controls={`item-menu-${item?.id}`}
                      aria-haspopup="true"
                      onClick={(e) => handleClick(e, item)}
                      size="large"
                      className={userCss.iconStyleAction}
                      color="primary"
                      sx={{ border: `1px solid #6A2382` }}
                    >
                      <MoreHorizIcon fontSize="medium" />
                    </IconButton>
                    <Menu
                      id={`item-menu-${item?.id}`}
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem onClick={() => handleNavigate()}>
                        <ListItemIcon>
                          <VisibilityIcon />
                        </ListItemIcon>
                        View
                      </MenuItem>
                      {/* {isSelectedStatus !== CONSTANT.STATUS.NOT_REGISTERED &&
                                        <MenuItem className={item?.status === CONSTANT.STATUS.ACTIVE ? userCss.active : userCss.deactive}
                                        >

                                            {item?.status === CONSTANT.STATUS.INACTIVE ? 'Active' : 'Inactive'}
                                        </MenuItem>} */}
                      {isSelectedStatus === CONSTANT.STATUS.ACTIVE ? (
                        <MenuItem
                          onClick={() =>
                            onChanageStatus(CONSTANT.STATUS.INACTIVE)
                          }
                        >
                          <ListItemIcon>
                            <PersonOffIcon />
                          </ListItemIcon>
                          Deactivate
                        </MenuItem>
                      ) : isSelectedStatus === CONSTANT.STATUS.INACTIVE ? (
                        <MenuItem
                          onClick={() =>
                            onChanageStatus(CONSTANT.STATUS.ACTIVE)
                          }
                        >
                          <ListItemIcon>
                            <PersonIcon />
                          </ListItemIcon>
                          Activate
                        </MenuItem>
                      ) : null}
                      {/* {isSelectedStatus === CONSTANT.STATUS.ACTIVE && <MenuItem
                                    >
                                        
                                        <ListItemIcon>
                                                <LockResetIcon />
                                            </ListItemIcon>
                                        Reset Password
                                    </MenuItem>} */}
                      {isSelectedStatus === CONSTANT.STATUS.NOT_REGISTERED && (
                        <MenuItem onClick={() => setisResendEmail(true)}>
                          <ListItemIcon>
                            <MarkEmailReadIcon />
                          </ListItemIcon>
                          Resend Email
                        </MenuItem>
                      )}
                      {isSelectedStatus !== CONSTANT.STATUS.ACTIVE && (
                        <MenuItem onClick={() => setIsCreateCaseload(true)}>
                          <ListItemIcon>
                            <DeleteIcon />
                          </ListItemIcon>
                          Delete
                        </MenuItem>
                      )}
                    </Menu>
                  </div>
                ),
              };
            })
          }
          paginationTotalRows={userList.totalPages}
          setLimitPerPage={(e: any) => setLimit(e)}
          limit={limit}
          pagination={true}
          setCurrentPage={(e: any) => setPage(e)}
        />
      </Grid>

      <>
        <ConfirmationModal
          open={isCreateCaseload}
          handleClose={() => setIsCreateCaseload(false)}
          title="Confirmation"
          message={"Are you sure you want to Delete User ?"}
          onConfirm={() => handleDeleteUser(selectedUserId)}
          onCancel={() => setIsCreateCaseload(false)}
        />

        <ConfirmationModal
          open={isResendEmail}
          handleClose={() => setisResendEmail(false)}
          title="Confirmation"
          message="Are you sure you want to Resend Email ?"
          onConfirm={() => resendActivation()}
          onCancel={() => setisResendEmail(false)}
        />
      </>
    </>
  );
};

export default memo(Listing);
