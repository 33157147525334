export const ADD_ADMIN_ACCOUNT = "ADD_ADMIN_ACCOUNT"
export const ADMIN_ACCOUNT_RESPONSE = "ADMIN_ACCOUNT_RESPONSE"
export const VIEW_MODULES = "VIEW_MODULES"
export const MODULES_RESPONSE = "MODULES_RESPONSE"
export const USER_LISTING = "USER_LISTING"
export const USER_FULL_LISTING = "USER_FULL_LISTING"
export const CLINICIAN_LISTING = "CLINICIAN_LISTING"
export const CLINICIAN_RESPONSE = "CLINICIAN_RESPONSE"
export const USER_RESPONSE = "USER_RESPONSE"
export const USER_FULL_RESPONSE = "USER_FULL_RESPONSE"
export const GET_USER_DETAIL = "GET_USER_DETAIL"
export const USER_DETAIL_RESPONSE = "USER_DETAIL_RESPONSE"
export const UPDATE_USER_ACCOUNT = "UPDATE_USER_ACCOUNT"
export const RE_ACTIVATION_MAIL_By_ID = "RE_ACTIVATION_MAIL_By_ID"
export const STATUS_CHANGED_By_ID = "STATUS_CHANGED_By_ID"
export const DELETE_USER_BY_ID = "DELETE_USER_BY_ID"

export const GET_OPEN_USER_DETAIL = "GET_OPEN_USER_DETAIL"
export const GET_OPEN_USER_RESPONSE = "GET_OPEN_USER_RESPONSE"
export const ADD_AddITIONAL_PARENT_BY_ID = "ADD_AddITIONAL_PARENT_BY_ID";
export const UPDATE_AddITIONAL_PARENT_BY_ID = "UPDATE_AddITIONAL_PARENT_BY_ID";
export const USER_FULL_RESPONSE_CLEAR = 'USER_FULL_RESPONSE_CLEAR'