
import React, { Suspense, memo, useEffect, useState } from 'react'
import { Box, Grid, IconButton, MenuItem, Menu, Typography, ListItemIcon, Tooltip, Chip, InputLabel, TextField } from '@mui/material';
import {
    AddCircle as AddCircleIcon,
    MoreHoriz as MoreHorizIcon,
    Delete as DeleteIcon,
    Visibility as VisibilityIcon,
} from '@mui/icons-material';
import {  useNavigate } from 'react-router-dom';
import Table from '../../../components/atoms/Table'
import { addRedflagSystemDate, changeTab, clearRedflagData, getRedFlag } from '../../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from "reselect";
import css from "./index.module.scss";
import CONSTANT from '../../../constants/constant';
import Search from "../../../components/atoms/Search"
import moment from 'moment';
import { calculateAge, formatNHSNumber } from "../../../constants/utils"
import constant from '../../../constants/constant';
import { CurrentUserData } from './interface';
import Modal from '../../../components/atoms/Modal';
import Button from '../../../components/atoms/Button';
import { useResponsive } from '../../../components/atoms/useResponsive';
import { useRejectedReferral } from './RejectedRefferal';
import ConfirmationModal from '../../../components/atoms/ConfirmationModal';
import { useClearSearch } from '../../../components/atoms/Hooks/useClearSearch';
const Export = React.lazy(() => import('../../../components/atoms/Export'));
const RedFlag = () => {
    const Navigate = useNavigate()
    const dispatch = useDispatch()
    const [sortType, setSortType] = useState('')
    const [isSystemOne, setisSystemOne] = useState(false);
    const [page, setPage] = useState(1)
    const [CaseLoadID, setCaseLoadID] = useState("");
    const [refType, setrefType] = useState("");
    const { setSearch, search, clearSearch } = useClearSearch();
    const [limit, setLimit] = useState(10)
    const {windowWidth,isMediumScreen} = useResponsive()
    const {
      handlerejectByID,
      formData,
      setFormData,
      selectedUserId,
      isRejectedRef,
      RefIDForPDF,
      anchorEl,
      setAnchorEl,
      setRefIDForPDF,
      setSelectedUserId,
      setisRejectedRef,
    } = useRejectedReferral();

    const columns =  ([
        {
            name: '#',
            cell: (row: any, index: number) => (((page - 1) * limit) + index + 1).toString().padStart(2, '0'),
            width: '50px',
            tblName: ''

        },
        {
            name: 'Referral ID',
            cell: (item: any, ind: number) => <span>{item.referral_id}</span>,
            sortable: false,
            width: "150px",
            tblName: 'referral_id'
        },
        {
            name: 'Referral Date/Time',
            cell: (item: any, ind: number) => <span>{moment(item.createdAt).format("DD/MM/YYYY")}</span>,
            sortable: true,
            width: "180px",
            tblName: 'createdAt'
        },
        {
            name: 'Patient Name',
            cell: (item: any, ind: number) => <span>{item.patient_name}</span>,
            selector: (item: any, ind: number) => item.patient_name,
            sortable: true,
            width: "150px",
            tblName: 'patient_name'
        },
        {
            name: "SystmOne Action",
            width: "180px",
            cell: (item: any, ind: number) =>
            
                <Box className={css.chipsStyle}>
                  {
                    [
                      {
                        label: "Referral Uploaded",
                        date: item?.system_one_referral_date,
                        key: "system_one_referral_date",
                        path: '1'
                      },

                    ].map(({ label, date, key, path }) => {
                      let chipColor = "gray";
                      let clickable = false;
    
                      if (date) {
                        chipColor = "green"; 
                      } else if (
                        (path.includes("1") &&
                          key === "system_one_referral_date") 
                      ) {
                        chipColor = "red"; 
                        clickable = true;
                      }
    
                      return (
                        <Tooltip
                          key={key}
                          title={
                            date
                              ? moment(date).utc().format("DD/MM/YYYY : HH:mm")
                              : "No date available"
                          }
                        >
                          <Chip
                            label={label}
                            size="small"
                            sx={{ color: chipColor,'& .MuiChip-label' : {
                              padding:0
                            }}}
                            onClick={() =>
                              clickable && handleChipClick(item?.id, key)
                            }
                          />
                        </Tooltip>
                      );
                    })
                  }
                </Box>,
      
            sortable: false,
            tblName: "action",
          },
        {
            name: 'Patient Age',
            cell: (item: any, ind: number) => <span>{item.age}</span>,
            sortable: false,
            width: "100px",
            tblName: 'age'
        },
        {
            name: 'NHS Number',
            width: "150px",
            cell: (item: any, ind: number) => <span>{item?.patient_nhs_number === '' ? '-' : formatNHSNumber(item?.patient_nhs_number)}</span>,
            sortable: false,
            tblName: 'patient_nhs_number'
        },
        {
            name: 'Source',
            width: "100px",
            cell: (item: any, ind: number) => <span>{item.status}</span>,
            sortable: false,
            tblName: 'status'
        },
        {
            name: 'Action',
            width: '80px',
            cell: (item: any, ind: number) => <span>{item.action}</span>,
            sortable: false,
            tblName: 'action'
        }
    ]);
    const redFlagReducer = (state: any) => state?.redFlag
    const redFlagData = createSelector(
        redFlagReducer,
        (state) => ({
            redFlagList: state?.data,

        })
    );
    const { redFlagList } = useSelector(redFlagData);

    const handleClick = (event: any, id: string) => {
        setAnchorEl(event.currentTarget);
        setSelectedUserId(id)
    };


    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        dispatch(changeTab('Red Flag'))
        dispatch(getRedFlag({
            limit,
            page,
            search,
            sortBy:sortType
        }));
        return () => {
            dispatch(clearRedflagData())
        }
    }, [page, search, limit,sortType])
    

    const handleNavigate = (type: string) => {
        if (type === 'view') {
            Navigate(`/referral/view/${selectedUserId}`)
        } else if (type === "reject") {
          setisRejectedRef(true);
        } else if (type === 'create-caseload') {
            Navigate(`/referral/create-caseload/${selectedUserId}`)
        }
    }

    const userData: CurrentUserData | null = JSON.parse(
        localStorage.getItem("loginResponse") || "null"
      );
    

      const handleChipClick = (id: string, type: string) => {
        setisSystemOne(true);
        setCaseLoadID(id);
        setrefType(type);
      };

      const handleOnchange = (e: any) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));

      };

      const systemSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const combinedDateTime = `${formData.date} ${formData.time}`;
        let payload = {
          [refType]: combinedDateTime,
        };
        dispatch(addRedflagSystemDate(CaseLoadID, payload));
        setTimeout(() => {
          dispatch(
            dispatch(getRedFlag({
                limit,
                page,
                search,
                sortBy:sortType
            }))
          );
        }, 500);
    
        setFormData({ date: "", time: "",reason:"" });
        setisSystemOne(false);
      };
    
      const isFormValid = formData.date !== "" && formData.time !== "";
      const isFormReasonValid = formData.reason !== ""

      const tableWidth = () => {
        const sidebarWidth = !isMediumScreen ? 0 : 270
        const adjustedWidth = windowWidth - sidebarWidth; 
        return `calc(${adjustedWidth}px)`;
      };
   
      const handleSort = (column: { tblName: string }, sortDirection: 'asc' | 'desc') => {
        const {tblName} = column;
        if(tblName === 'createdAt'){
          setSortType(sortDirection)
        }
      };

    return (
        <>  
            <Box className="innerheader">
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item xs={6} className="searchInputbx">
                        <Search searchCallBack={(e: string) => setSearch(e)} searchValue={search}/>
                    </Grid>
                    <Grid item xs={6} display={"flex"} gap={2} justifyContent={"end"}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Export data={redFlagList?.referrals} columns={columns}></Export>
                        </Suspense>
                    </Grid>
                </Grid>
            </Box>
            <Grid className='tableWrapper' style={{maxWidth:tableWidth()}}>
                <Table columns={columns}
                       handleSort={handleSort}
                className={'userLisingTable'}
                    setLimitPerPage={(e: any) => setLimit(e)}
                    limit={limit}
                    data={redFlagList?.referrals && redFlagList?.referrals?.map((item: any, index: number) => {
                        return ({
                            ...item,
                            status:
                            item.submitted_by === CONSTANT.SCOPE.SCHOOL ? (
                              <Typography className="activeStatus">Education</Typography>
                            ) : item.submitted_by === CONSTANT.SCOPE.CLIENT_ADMIN ? (
                              <Typography className="deactiveStatus">Admin</Typography>
                            ) : item.submitted_by === CONSTANT.SCOPE.UNKNOWN ? (
                              <Typography className="deactiveStatus">Open URL</Typography>
                            ) : (
                              <></>
                            ),
                            age: calculateAge(item.patient_dob),
                            action:
                                <div key={index}>
                                    <IconButton
                                        aria-controls={`item-menu-${item?.id}`}
                                        aria-haspopup="true"
                                        className="iconStyleAction"
                                        onClick={(e) => handleClick(e, item?.id)}
                                        color='primary'
                                        sx={{ border: `1px solid #6A2382` }} >

                                        <MoreHorizIcon fontSize="medium" />
                                    </IconButton>


                                    <Menu
                                        id={`item-menu-${item?.id}`}
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <MenuItem onClick={() => handleNavigate("view")} >
                                            <ListItemIcon>
                                                <VisibilityIcon />
                                            </ListItemIcon>
                                            View
                                        </MenuItem>
                                        {userData?.scope !== constant.SCOPE.CLINICIAN &&  <MenuItem onClick={() => handleNavigate("create-caseload")}>
                                            <ListItemIcon>
                                                <AddCircleIcon />
                                            </ListItemIcon>
                                            Move to Active Referral
                                        </MenuItem>}
                                        
                  <MenuItem onClick={() => handleNavigate("reject")}>
                    <ListItemIcon>
                      <DeleteIcon />
                    </ListItemIcon>
                    Reject
                  </MenuItem>
                
                                    </Menu>
                                </div>
                        })

                    }
                    )}
                    paginationTotalRows={redFlagList?.totalPages}
                    pagination={true}
                    setCurrentPage={(e: any) => setPage(e)} />
            </Grid>

            <Modal
        open={isSystemOne}
        handleClose={() => {
          setisSystemOne(false);
        }}
        title={"Add SystmOne Date/Time"}
      >
        <Box className={css.clinicModal}>
          <form onSubmit={systemSubmit}>
            <Grid container spacing={2} py={4}>
              <Grid item sx={{ width: "50%" }}>
                <InputLabel
                  htmlFor="outlined-adornment-password"
                  sx={{
                    textAlign: "left",
                    fontFamily: "poppins",
                    marginBottom: "0.5rem",
                  }}
                >
                  Select Date
                </InputLabel>
                <TextField
                  type="date"
                  name="date"
                  variant="outlined"
                  onChange={handleOnchange}
                  sx={{ width: "100%" }}
                ></TextField>
              </Grid>
              <Grid item sx={{ width: "50%" }}>
                <InputLabel
                  htmlFor="outlined-adornment-password"
                  sx={{
                    textAlign: "left",
                    fontFamily: "poppins",
                    marginBottom: "0.5rem",
                  }}
                >
                  Select Time
                </InputLabel>
                <TextField
                  type="time"
                  name="time"
                  variant="outlined"
                  onChange={handleOnchange}
                  sx={{ width: "100%" }}
                ></TextField>
              </Grid>
            </Grid>
            <Grid
              className={css.btnWrapper}
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                alignItem: "center",
                mt: "15px",
              }}
            >
              <Button
                sx={{ width: "30%" }}
                variant={"contained"}
                disabled={!isFormValid}
                type="submit"
              >
                Submit
              </Button>
            </Grid>
          </form>
        </Box>
      </Modal>

      <ConfirmationModal
        open={isRejectedRef}
        handleClose={() => setisRejectedRef(false)}
        title="Reject Referral"
        message={
          <Grid item sx={{ width: "100%" }}>
            <InputLabel
              htmlFor="outlined-adornment-password"
              sx={{
                textAlign: "left",
                fontFamily: "poppins",
                marginBottom: "0.5rem",
              }}
            >
              Reason
            </InputLabel>
            <TextField
              multiline
              sx={{ marginBottom: "0.5rem", width: "100%" }}
              placeholder="Write a Reason..."
              rows={4}
              name="reason"
              variant="outlined"
              onChange={handleOnchange}
            ></TextField>
          </Grid>
        }
        onConfirm={()=> handlerejectByID()}
        confirmLabel={!formData.reason ? "" : "Reject"}
        cancelLabel={"Cancel"}
        onCancel={() => setisRejectedRef(false)}
      />
        </>
    )
}

export default memo(RedFlag)


