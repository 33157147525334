import React, { useEffect, useState } from 'react';
import {
  Grid,
} from '@mui/material';
import classes from "./index.module.scss"
import CommonField from './CommonField';
const Social_Interaction = ({ questions,patientName, setQuestionData, isError, setIsError,setIsDisableContinue }: any) => {
  let QuestionNumber = questions.personalDetails.length + questions.familyHistory.length + questions.communication.length
  const handleAnswerChange = (index: number, value: any, key: string) => {
    let tempQuestions = { ...questions };
    let tempPersonDetail = [...tempQuestions.socialInteraction];
    tempPersonDetail[index].question = tempPersonDetail[index].question.replace('[forename]', patientName);
    tempPersonDetail[index].answer = value;
    setQuestionData({ ...tempQuestions, socialInteraction: tempPersonDetail });
  };
 
  useEffect(() => {
    setIsError(false)
  }, [])
  return (
    <>
      <Grid container direction="column" spacing={2} className={classes.Referral}>
        {questions?.socialInteraction && questions?.socialInteraction?.map((ques: any, index: number) => (
          <>
            {/* {handleRenderComponent(ques.type, index, ques.question, ques.answer, ques.option, "socialInteraction", ques.isRequired)} */}
            {<CommonField
              type={ques.type}
              index={index}
              question={ques.question.replace('[forename]',patientName)}
              answer={ques.answer}
              options={ques.option}
              key={"socialInteraction"}
              required={ques.isRequired}
              question_number={QuestionNumber}
              questions={questions?.socialInteraction}
              isError={isError}
              handleAnswerChange={handleAnswerChange}
              ques={ques}
              setIsDisableContinue={setIsDisableContinue}
              totalQuestion={questions?.socialInteraction?.length}
            />}

          </>
        ))}
      </Grid>
    </>
  );
}

export default Social_Interaction;
