import { Get_Logout_Response, Get_Login_Response, Get_Profile_Response, Get_logoType, VERIFY_LINK_RESPONSE, VERIFY_DOMAIN_RESPONSE } from './actionType'


const initialValues = {
    linkResponse: '',
    domainResponse: '',
    loginResponse: '',
    loading: false,
    error: null,
};

export const Login_Response = (state = initialValues, action: any) => {

    switch (action.type) {
        case Get_Login_Response:
            state = { ...state, loginResponse: action?.payload };
            return state;

        case VERIFY_LINK_RESPONSE:
            state = { ...state, linkResponse: action?.payload }
            return state;

        case VERIFY_DOMAIN_RESPONSE:
            state = { ...state, domainResponse: action?.payload };
            return state;
            
        default:
            return state;
    }
}
