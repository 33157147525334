import React, { useEffect, useState } from 'react';
import {
  Card, Grid, TextField, Typography, RadioGroup, FormControlLabel, Radio,
  FormControl, FormLabel, Select, MenuItem, Checkbox
} from '@mui/material';
import classes from "./index.module.scss"
import CommonField from './CommonField';
function BirthDetails({ questions,patientName, setQuestionData, isError, setIsError,setIsDisableContinue }: any) {
  let QuestionNumber = questions.personalDetails.length + questions.familyHistory.length + questions.communication.length + 
  questions.socialInteraction.length + questions.playImagination.length + questions.sensory.length + questions.motorMannerisms.length
  + questions.attentionActivity.length

  const handleAnswerChange = (index: number, value: any, key: string) => {
    let tempQuestions = { ...questions };
    let tempPersonDetail = [...tempQuestions.birthDetails];
    tempPersonDetail[index].question = tempPersonDetail[index].question.replace('[forename]', patientName);
    tempPersonDetail[index].answer = value;
    setQuestionData({ ...tempQuestions, birthDetails: tempPersonDetail });
  };


  useEffect(() => {
    setIsError(false)
  }, [])

  return (

    <Grid container direction="column" spacing={2} className={classes.Referral}>
      {questions?.birthDetails && questions?.birthDetails?.map((ques: any, index: number) => (
        <>
          {/* {handleRenderComponent(ques.type, index, ques.question, ques.answer, ques.option, "birthDetails", ques.isRequired)} */}
          {<CommonField
            type={ques.type}
            index={index}
            question={ques.question.replace('[forename]',patientName)}
            answer={ques.answer}
            options={ques.option}
            key={"birthDetails"}
            required={ques.isRequired}
            question_number={QuestionNumber}
            questions={questions?.birthDetails}
            isError={isError}
            handleAnswerChange={handleAnswerChange}
            ques={ques}
            setIsDisableContinue={setIsDisableContinue}
            totalQuestion={questions?.birthDetails?.length}
          />}
          {/* {isError && ques.answer === "" && ques.isRequired && <p className={classes.errorColor}>This field is required</p>} */}
        </>))}
    </Grid>
  );
}

export default BirthDetails;
