import React, { useEffect, useRef, useState } from 'react';
import { Card, Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, TextField, Typography, Select, MenuItem } from '@mui/material';
import classes from "./index.module.scss"
import CommonField from './CommonField';


const Communication = ({ patientName, questions, setQuestionData, isError, setIsError, setIsDisableContinue }: any) => {
  let QuestionNumber = questions?.personalDetail?.length

  const handleAnswerChange = (index: number, value: any, key: string, updatedQuestion: string, updatedAdditionalInfo: string, updatedAdditionalQuestion: string, updatedIsHeading: string) => {
    let tempQuestions = { ...questions };
    let tempCommunication = [...tempQuestions.communication];
    tempCommunication[index].answer = value;

    if (tempCommunication[index]) {
      if (tempCommunication[index].question) {
        tempCommunication[index].question = updatedQuestion
      }
      if (tempCommunication[index].additional_info) {
        tempCommunication[index].additional_info = updatedAdditionalInfo
      }
      if (tempCommunication[index].isAdditionalStructure && tempCommunication[index].isAdditionalStructure.question) {
        tempCommunication[index].isAdditionalStructure.question = updatedAdditionalQuestion
      }
    }
    if (tempCommunication[index].question_id === "ques13" && tempCommunication[index].answer === 'No') {
      tempCommunication[index + 1].isRequired = false;
      tempCommunication[index + 2].isRequired = false;
      tempCommunication[index + 1].answer = '';
      tempCommunication[index + 2].answer = '';
    } else if (tempCommunication[index].question_id === "ques13" && tempCommunication[index].answer === 'Yes') {
      tempCommunication[index + 1].isRequired = true;
      tempCommunication[index + 2].isRequired = true;
    }

    setQuestionData({ ...tempQuestions, communication: tempCommunication });
  };

  const handleAdditionalChange = (index: number, value: any) => {
    let tempQuestions = { ...questions };
    let tempOtherInformation = [...tempQuestions.communication];
    tempOtherInformation[index].isAdditionalStructure.answer = value;
    setQuestionData({ ...tempQuestions, communication: tempOtherInformation });
  }


  useEffect(() => {
   

    setIsError(false)
  }, [])
  return (
    <Grid container spacing={3} className={classes.Report} >

      {questions?.communication && questions?.communication?.map((ques: any, index: number) => (
        <>
          {/* {handleRenderComponent(ques.type, index, ques.question, ques.answer, ques.option, "reason", ques.isRequired)} */}
          {<CommonField
            type={ques.type}
            index={index}
            question={ques.question}
            answer={ques.answer}
            options={ques.option}
            fileType={"communication"}
            required={ques.isRequired}
            question_number={QuestionNumber}
            questions={questions?.communication}
            isError={isError}
            handleAnswerChange={handleAnswerChange}
            ques={ques}
            setIsDisableContinue={setIsDisableContinue}
            totalQuestion={questions?.communication?.length}
            AdditinalInfo={ques.additional_info}
            isAdditional={ques.isAdditional}
            isAdditionalQuestion={ques.isAdditionalStructure}
            handleAdditionalChange={handleAdditionalChange}
            patientName={patientName}
          />}
          {/* {isError && ques.answer === "" && ques.isRequired && <p className={classes.errorColor}>This field is required</p>} */}
        </>
      ))}
    </Grid>
  );
}

export default Communication;
