import { useState, useEffect } from "react";
import constant from "../../../constants/constant";
import { useDispatch } from "react-redux";
import { SetActiveTab, rejectRefferalByID } from "../../../redux/action";
import { useNavigate } from "react-router-dom";

export const useRejectedReferral = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [formData, setFormData] = useState({ date: "", time: "", reason: "" });
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [isRejectedRef, setisRejectedRef] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [RefIDForPDF, setRefIDForPDF] = useState<string | null>(null);

  const handlerejectByID = async () => {
    const { reason } = formData;
    const payload = {
      status: constant.REFERRAL_STATUS.REJECTED,
      reason,
    };
  
    try {
      await dispatch(rejectRefferalByID(selectedUserId || RefIDForPDF, payload));
      setisRejectedRef(false);
      setAnchorEl(null);
      dispatch(SetActiveTab(0));
      setTimeout(() => {
        Navigate("/manage-caseload/rejected");
      }, 100);
  
    } catch (error) {
      console.error("Failed to reject referral:", error);
    } finally {
      setSelectedUserId(null);
      setRefIDForPDF(null);
    }
  };
  
  return {
    handlerejectByID,
    formData,
    setFormData,
    selectedUserId,
    isRejectedRef,
    RefIDForPDF,
    anchorEl,
    setAnchorEl,
    setRefIDForPDF,
    setSelectedUserId,
    setisRejectedRef,
  };
};
