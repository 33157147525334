import { call, put, takeLatest } from 'redux-saga/effects';
import { ADD_ADMIN_ACCOUNT, VIEW_MODULES, USER_LISTING, GET_USER_DETAIL, UPDATE_USER_ACCOUNT, RE_ACTIVATION_MAIL_By_ID, 
    STATUS_CHANGED_By_ID, USER_FULL_LISTING, DELETE_USER_BY_ID, GET_OPEN_USER_DETAIL, GET_OPEN_USER_RESPONSE, ADD_AddITIONAL_PARENT_BY_ID, UPDATE_AddITIONAL_PARENT_BY_ID } from './actionType';
import { AxiosResponse } from 'axios';
import service from '../../api/services';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { userResponse, modulesResponse, getUserDetail, userFullResponse } from "./action"

function* addAdminAccount({ questionData, navigate, param }: any): Generator<any, AxiosResponse<any>, any> {
    const urlMap: any = {
        "admin": `/user-management/admin`,
        "clinician": `/user-management/clinician`,
        "parent": `/user-management/parent`,
        "education_setting": `/user-management/education_setting`
    };
    const url: any = urlMap[param] || '';
    try {
        const response: AxiosResponse<any> = yield service.User_Management.add(questionData);
        if (response.status === 201 || response.status === 200) {
            toast.success(response?.data?.message);
            navigate(url)
        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}
function* updateAdminAccount({ id, questionData, navigate, param }: any): Generator<any, AxiosResponse<any>, any> {
    const urlMap: any = {
        "admin": `/user-management/admin`,
        "clinician": `/user-management/clinician`,
        "parent": `/user-management/parent`,
        "education_setting": `/user-management/education_setting`
    };
    const url: any = urlMap[param] || '';
    try {
        const response: AxiosResponse<any> = yield service.User_Management.update(id, questionData);
        if (response.status === 200 || response.status === 201) {
            toast.success(response?.data?.message);
            navigate(url)

        } else {
            toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}
function* getModule({ headers }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.User_Management.viewModule(headers);

        yield put(modulesResponse(response.data))
        if (response.status === 201) {
            // toast.success(response?.data?.message);
            // navigate("/referral");

        } else {
            // toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}

function* getUserList({ headers, type }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.User_Management.viewUser(headers);

        // yield put(userResponse(response.data))

        yield put(type === USER_LISTING ? userResponse(response.data) : userFullResponse(response.data));
        if (response.status === 201) {
            // toast.success(response?.data?.message);
            // navigate("/referral");

        } else {
            // toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}


function* reActivationMailbyId({ id }: any): Generator<any, AxiosResponse<any>, any> {
    try {

        const response: any = yield service.User_Management.ReActivationMailById(id);
        if (response.status === 200 || 201) {
            toast.success(response.data.message);
        } else {
            toast.error(response.data.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {

        toast.error(error?.response?.data?.message);
        return error
    }
}


function* getDetailById({ id }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.User_Management.getUserById(id);

        yield put(getUserDetail(response.data))
        if (response.status === 201) {
            // toast.success(response?.data?.message);
            // navigate("/referral");

        } else {
            // toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}


function* statusChangedbyId({ id, data, listing }: any): Generator<any, AxiosResponse<any>, any> {
    try {

        const response: any = yield service.User_Management.StatusChangedById(id, data);
        if (response.status === 200 || 201) {
            toast.success(response.data.message);

            // yield new Promise(resolve => setTimeout(resolve, 2000));
            if (listing) {
                let headers = listing;
                yield call(getUserList, { headers, type: USER_LISTING });
            }

        } else {
            toast.error(response.data.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {

        toast.error(error?.response?.data?.message);
        return error
    }
}



function* addAdditionalParent({data}: any): Generator<any, AxiosResponse<any>, any> {
    try {
        
        const response: any = yield service.User_Management.add(data);
        if (response.status === 200 ||201) {
            toast.success(response.data.message);
            
            // yield new Promise(resolve => setTimeout(resolve, 2000));
         
  
        } else {
            toast.error(response.data.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {

        toast.error(error?.response?.data?.message);
        return error
    }
}

function* updateAdditionalParent({datax, data}: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const { id, ...updatedData } = data;
        data = updatedData;
        const response: any = yield service.User_Management.update(datax.id, updatedData);
        if (response.status === 200 ||201) {
            toast.success(response.data.message);
        } else {
            toast.error(response.data.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {

        toast.error(error?.response?.data?.message);
        return error
    }
}






function* deleteUser({id,listing}: any): Generator<any, AxiosResponse<any>, any> {
    try {

        const response: any = yield service.User_Management.deleteUser(id);
        if (response.status === 200 || 201) {
            toast.success(response.data.message);

            // yield new Promise(resolve => setTimeout(resolve, 2000));
            if (listing) {
                let headers = listing;
                yield call(getUserList, { headers, type: USER_LISTING });
            }

        } else {
            toast.error(response.data.message);
        }
        return response; // add this line to return the response
    } catch (error: any) {

        toast.error(error?.response?.data?.message);
        return error
    }
}

function* getOpenUserList({ headers, type }: any): Generator<any, AxiosResponse<any>, any> {
    try {
        const response: any = yield service.User_Management.viewOpenUser(headers);

        // yield put(userResponse(response.data))

        // yield put(type === USER_LISTING ? userResponse(response.data) : userFullResponse(response.data));
        if (response.status === 201 || response.status === 200 || response.statusCode === 201 || response.statusCode === 200) {
            yield put({ type: GET_OPEN_USER_RESPONSE, payload: response.data })
            // toast.success(response?.data?.message);
            // navigate("/referral");

        } else {
            // toast.error(response?.data?.message);
        }
        return response;
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
        return error;
    }
}


function* referralSaga() {
    yield takeLatest(ADD_ADMIN_ACCOUNT, addAdminAccount);
    yield takeLatest(VIEW_MODULES, getModule);
    // yield takeLatest(USER_LISTING, getUserList);
    yield takeLatest(USER_LISTING, (action) => getUserList({ ...action, type: USER_LISTING }));
    yield takeLatest(USER_FULL_LISTING, (action) => getUserList({ ...action, type: USER_FULL_LISTING }));
    yield takeLatest(GET_USER_DETAIL, getDetailById);
    yield takeLatest(UPDATE_USER_ACCOUNT, updateAdminAccount);
    yield takeLatest(RE_ACTIVATION_MAIL_By_ID, reActivationMailbyId)
    yield takeLatest(STATUS_CHANGED_By_ID, statusChangedbyId)
    yield takeLatest(ADD_AddITIONAL_PARENT_BY_ID, addAdditionalParent)
    yield takeLatest(UPDATE_AddITIONAL_PARENT_BY_ID, updateAdditionalParent)

    yield takeLatest(DELETE_USER_BY_ID, deleteUser)
    yield takeLatest(GET_OPEN_USER_DETAIL, getOpenUserList)

}

export default referralSaga;
