import React, { memo, useEffect, useState } from 'react';
import withPdf from '../../../components/atoms/WithPdf';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from "reselect";
import constant from '../../../constants/constant';
import { getParentReport, getSchoolReport } from '../../../redux/action';
import ParentReport from './ParentReport';
import { useLocation, useParams } from 'react-router-dom';
import SchoolReport from './SchoolReport';
import Pdf from '../../../components/atoms/Pdf';
const ParentReducer = (state: any) => state?.parentReducer
const SchoolReducer = (state: any) => state?.schoolReducer



const Openpdf = () => {
    const [component, setComponent] = useState<any>(null);
    const dispatch = useDispatch()
    const location = useLocation()
    const pathParts = location.pathname.split('/').filter(Boolean);
    const param = pathParts[0]
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            if (param === 'parent-report') {
                dispatch(getParentReport({ caseload_id: id }));
            } else if (param === 'education-report') {
                dispatch(getSchoolReport({ caseload_id: id }));
            }
        }
    }, [id, param]); // Include param in the dependency array
    

    const ParentData = createSelector(
        ParentReducer,
        (state) => ({
            parentReport: state?.report?.data,
        })
    );

    const SchoolData = createSelector(
        SchoolReducer,
        (state) => ({
            schoolReport: state?.report?.data,
        })
    );

    const { parentReport } = useSelector(ParentData);
    const { schoolReport } = useSelector(SchoolData);


    useEffect(() => {
        if (param === 'parent-report') {
          setComponent(Pdf);
        } else if (param === 'education-report') {
          setComponent(Pdf);
        }
      }, [param]);
    
      if (!component) return null;

      const EnhancedComponent = withPdf(component);

    return (
        <>
          {param === 'parent-report' && (
            <EnhancedComponent
              data={parentReport}
              title={constant.PATHWAY_STATUS.PARENT_REPORT_RECEIVED}
              fname={parentReport?.personalDetails}
              mode={'AUTOMATIC'}
            />
          )}
          {param === 'education-report' && (
            <EnhancedComponent
              data={schoolReport}
              title={constant.PATHWAY_STATUS.SCHOOL_REPORT_RECEIVED}
              fname={schoolReport?.personalDetail}
              mode={'AUTOMATIC'}
            />
          )}
        </>
      );
};

export default memo(Openpdf);