import React, { FC, useEffect, useRef, useState } from 'react';
import {
    Card,
    Grid,
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    FormGroup,
    MenuItem,
    InputLabel,
    Select,
    FormHelperText
} from '@mui/material';
import CustomSelect from '../../../components/atoms/Select'
import classes from './index.module.scss';
import atoms from '../../../components/atoms'
import moment from 'moment';
interface MyComponentProps extends React.HTMLAttributes<HTMLElement> {
    type: any;
    index: number;
    question: any;
    answer: any;
    options: any;
    fileType: string; // Note: `key` is already part of `IntrinsicAttributes` and should not be redefined.
    required: boolean;
    question_number: any;
    questions: any;
    isError: boolean;
    ques: any;
    setIsDisableContinue: any;
    totalQuestion: number;
    GridSize?: number;
    AdditinalInfo?: string;
    isAdditional?: boolean;
    isAdditionalQuestion?: any;
    handleAdditionalChange?: any;
    patientName?: string;
    isHeading?: any;
    isHeadingLine?: any;
    isNested?: any;
    isNestedStructure?: any;
    handleNestedAnswerChange?: any;
    handleAnswerChange: (index: number, value: any, key: string, updatedQuestion: string, updatedAdditionalInfo: string, updatedAdditionalQuestion: string, updatedIsHeading: string) => void;
}
const { TextField } = atoms
const CommonField: React.FC<MyComponentProps> = ({ handleAdditionalChange, patientName, type, isNested
    , isNestedStructure, index, question, answer, isHeading, options, GridSize, fileType, required, handleNestedAnswerChange, isHeadingLine, isAdditionalQuestion, isAdditional, AdditinalInfo, question_number, totalQuestion, questions, isError, handleAnswerChange, ques, setIsDisableContinue }: any) => {
    const inputRef: any = useRef(null);
    const currentDate = moment().format('DD-MM-YYYY');
    const splitPatientName = patientName ? patientName.split(" ") : null;
    const patientFirstName = splitPatientName ? splitPatientName[0] : null;

    let updatedQuestion = question ? question.replace('patient_name', `${patientFirstName}'s` || '') : '';
    let updatedIsHeading = isHeading ? isHeading.replace('patient_name', `${patientFirstName}'s` || '') : null;
    let updatedAdditionalQuestion = isAdditionalQuestion && isAdditionalQuestion.question ? isAdditionalQuestion.question.replace('patient_name', `${patientFirstName}'s` || '') : null;
    let updatedAdditionalInfo = AdditinalInfo ? AdditinalInfo.replace('patient_name', `${patientFirstName}'s` || '') : null;




    const isEmailCheck = (val: string) => {
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!regEmail.test(val)) {
            return true
        } else {
            return false
        }
    }

    const handleEmailInput = (index: number, e: string, key: string) => {
        handleAnswerChange(index, e, key, updatedQuestion, updatedAdditionalInfo, updatedAdditionalQuestion, updatedIsHeading)
        if (isEmailCheck(e)) {
            setIsDisableContinue(true)
        }
        else {
            setIsDisableContinue(false)
        }
    }


    return (
        <Grid item lg={GridSize} md={12} sm={12} className={fileType !== "personalDetail" ? classes.card : classes.card_personal_detail} key={index}>
            {fileType !== "personalDetail" && <Typography className={classes.questionTitle}>Question {question_number + index + 1} to {totalQuestion + question_number}</Typography>}
            {isHeadingLine && <Grid display={'flex'} sx={{ gap: '5px', mb: '10px' }}>
                <Typography className={`${classes.questionText}`}>
                    {updatedIsHeading}
                </Typography>
            </Grid>}

            <Grid display={'flex'} sx={{ gap: '5px' }}>
                <Typography className={`${classes.questionText} ${!required && classes.isDisabled}`}>

                    Q.{question_number + index + 1}
                </Typography>
                <Typography className={`${classes.questionText} ${!required && classes.isDisabled}`}>

                    {updatedQuestion} {(required && ques?.isCompulsory) && <span style={{ color: 'red' }}>*</span>}
                </Typography>
            </Grid>

            {AdditinalInfo && <Grid sx={{ ml: '27px', color: '#F57C00' }}>
                <Typography className={`${classes.questionText} `}>
                    {updatedAdditionalInfo} {(required && AdditinalInfo) && <span style={{ color: 'red' }}>*</span>}
                </Typography>
            </Grid>}
            {type === 'radio' && (
                <RadioGroup
                    value={answer}
                    onChange={(e) => handleAnswerChange(index, e.target.value, fileType, updatedQuestion, updatedAdditionalInfo, updatedAdditionalQuestion, updatedIsHeading)}
                    className={classes.radioGroup}

                >
                    {options && options.map((option: string, optionIndex: number) => (
                        <FormControlLabel disabled={!required} key={optionIndex} value={option} control={<Radio ref={inputRef} className={classes.formControlLabel} />} label={option} />
                    ))}
                </RadioGroup>
            )}
            {(type === "text" || type === "number" || type === "date" || type === "textArea") && (
                <TextField
                    fullWidth
                    ref={inputRef}
                    value={answer}
                    autoFocus={index === 0 ? true : false}
                    disabled={ques.question_id === "ques75" || ques.question_id === "ques74"|| ques.question_id === "ques2" || !required}
                    type={type === "textArea" ? 'text' : type}
                    onChange={(e: any) => handleAnswerChange(index, e.target.value, fileType, updatedQuestion, updatedAdditionalInfo, updatedAdditionalQuestion, updatedIsHeading)}
                    placeholder={type === "date" ? "DD/MM/YYYY" : type === "email" ? "Please Enter Email" : type === "textArea" ? "Please describe" : "Please Enter"}
                    variant="outlined"
                    multiline={type === "textArea"}
                    rows={type === "textArea" ? 4 : 1}
                    inputProps={{ maxLength: 500, min: "1995-01-01", max: currentDate }} />
            )}
            {type === "email" &&
                <TextField
                    fullWidth
                    type={"email"}
                    ref={inputRef}
                    autoFocus={index === 0 ? true : false}
                    value={answer}
                    disabled={!required}
                    variant="outlined"
                    placeholder="Enter Email"
                    onChange={(e: any) => handleEmailInput(index, e.target.value, fileType)}
                    inputProps={{
                        pattern: '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$',
                    }}
                    error={(answer && isEmailCheck(answer)) && 'Please enter a valid email address'}
                />

            }
            {type === "file" &&
                <input
                    type={"file"}
                    ref={inputRef}
                    value={answer}
                    disabled={!required}
                    placeholder="Select file"
                    onChange={(e: any) => console.log("file selected", e)}
                />
            }
            {(type === "dropdown" && ques.question_id === 'ques22') &&
                <CustomSelect
                    fullWidth
                    ref={inputRef}
                    autoFocus={index === 0 ? true : false}
                    placeholder={"Select ..."}
                    isMulti={false}
                    disableCloseOnSelect={false}
                    defaultValue={answer}
                    options={options}
                    onChange={(e: any) => handleAnswerChange(index, e, fileType, updatedQuestion, updatedAdditionalInfo, updatedAdditionalQuestion, updatedIsHeading)}
                />
            }
            {(type === "dropdown" && ques.question_id !== 'ques22') &&
                <FormControl fullWidth>
                    <Select
                        className={classes.select}
                        ref={inputRef}
                        name="gender"
                        value={answer}
                        disabled={!required}
                        placeholder={"select"}
                        onChange={(e) => handleAnswerChange(index, e.target.value, fileType, updatedQuestion, updatedAdditionalInfo, updatedAdditionalQuestion, updatedIsHeading)}
                        displayEmpty
                    >
                        <MenuItem value="" disabled>
                            Select
                        </MenuItem>
                        {options && options.map((option: string, optionIndex: number) => (
                            <MenuItem key={optionIndex} value={option}  >{option}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            }
            {isError && answer === "" && required && ques.isCompulsory && <FormHelperText error>This field is required</FormHelperText>}

            {isAdditional &&
                <Grid sx={{ ml: '27px' }}>
                    {isAdditionalQuestion?.question &&
                        <Grid className={`${classes.questionText} ${!isAdditionalQuestion.isRequired && classes.isDisabled}`}>
                            {updatedAdditionalQuestion} {isAdditionalQuestion?.isRequired && <span style={{ color: 'red' }}>*</span>}
                        </Grid>}
                    <Grid>
                        <TextField
                            fullWidth
                            ref={inputRef}
                            value={isAdditionalQuestion.answer}
                            disabled={!isAdditionalQuestion.isRequired}
                            type={isAdditionalQuestion.type}
                            onChange={(e: any) => handleAdditionalChange(index, e.target.value)}
                            placeholder={"Please describe "}
                            variant="outlined"
                            multiline={true}
                            rows={4}
                            inputProps={{ maxLength: 500 }} />
                    </Grid>
                    {isAdditional && isError && isAdditionalQuestion.answer === "" && isAdditionalQuestion.isRequired && <FormHelperText error>This field is required</FormHelperText>}
                </Grid>
            }
            {isNested &&
                <Grid sx={{ ml: '27px', mt: '5px' }}>
                    {isNestedStructure.map((item: any, ind: number) => {
                        return (
                            <>
                                {(item.question && item?.isRequired) &&
                                    <Grid className={`${classes.questionText}}`}>
                                        {item.question} {item?.isRequired && <span style={{ color: 'red' }}>*</span>}
                                    </Grid>}
                                <Grid>
                                    {(item?.isRequired && (item?.type === 'radio')) && (
                                        <RadioGroup
                                            value={item.answer}
                                            onChange={(e) => handleNestedAnswerChange(ind, index, e.target.value, fileType)}
                                            className={classes.customRadioColor}

                                        >
                                            {item?.option && item.option.map((option: string, optionIndex: number) => (
                                                <FormControlLabel disabled={!item.isRequired} key={optionIndex} value={option} control={<Radio ref={inputRef} className={classes.formControlLabel} />} label={option} />
                                            ))}
                                        </RadioGroup>
                                    )}
                                    {isNested && isError && item.answer === "" && item.isRequired && <FormHelperText error>This field is required</FormHelperText>}
                                </Grid>

                            </>
                        )
                    })}
                </Grid>
            }
        </Grid>
    );
}

export default CommonField