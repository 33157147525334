import React, { memo, useEffect, useState } from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import { privateRoutes, publicRoutes } from "./routes"
import AuthLayout from './layout/AuthLayout'
import { allScope } from './constants/defaultValues'
import NonAuthLayout from './layout/NonAuthLayout'
import './assets/scss/common.scss'
import Interceptor from './api/interceptor';
import notFound from './assets/Images/404.webp'
import { isLoaderLoading } from './context';


const NotFound = () => <div>
  <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'100vh'}}> 
    <img src={notFound}  alt='not_found'/>
  </div>
</div>;
function App() {
const [isLoading, setIsLoading] = useState<any>(false);


  return (
    <>
    <isLoaderLoading.Provider value={{ isLoading, setIsLoading }}>

    <div className="App">
      <Interceptor />
         <Routes>
      {privateRoutes.map((route, idx) => (
        route.scope === allScope ? (
          <Route
            path={route.path}
            element={<AuthLayout>{route.component}</AuthLayout>}
            key={idx}
          />
        ) : null
      ))}
      {publicRoutes.map((route, idx) => (
        <Route
          path={route.path}
          element={<NonAuthLayout>{route.component}</NonAuthLayout>}
          key={idx}
        />
      ))}
      <Route path="*" element={<NotFound />} /> 
    </Routes>
    </div>
    </isLoaderLoading.Provider>
    </>

  );
}


export default memo(App);