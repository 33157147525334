
import React, { Suspense, memo, useEffect, useState } from 'react'
import { Box, Grid, IconButton, MenuItem, Menu, Typography, ListItemIcon, List, ListItem, ListItemButton, ListItemText, Avatar } from '@mui/material';
import Button from '../../../components/atoms/Button'
import {
    FileDownloadOutlined as FileDownloadOutlinedIcon,
    AddCircle as AddCircleIcon,
    FilterAlt as FilterAltIcon,
    MoreHoriz as MoreHorizIcon,
    Visibility as VisibilityIcon,
    Delete as DeleteIcon,
} from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import classes from './index.module.scss'
import Table from '../../../components/atoms/Table'
import newRef from '../../../assets/Images/newRef.svg';
import activeRef from '../../../assets/Images/activeRef.svg';
import { changeTab, viewReferral } from '../../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from "reselect";
import CONSTANT from '../../../constants/constant';
import Search from "../../../components/atoms/Search"
import moment from 'moment';
import { calculateAge, formatNHSNumber, isEmptyObject } from "../../../constants/utils"
import Export from '../../../components/atoms/Export';
import { useClearSearch } from '../../../components/atoms/Hooks/useClearSearch';
const FilterChip = React.lazy(() => import('../../../components/atoms/Chips'));
const FilterBox = React.lazy(() => import('../../../components/atoms/Filter'));

const Index = () => {
    const Navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState(null);
    const [page, setPage] = useState(1)
    const { setSearch, search, clearSearch } = useClearSearch();
    const [parentValue, setParentValue] = useState<any>([]);
    const [filterOPtion, setfilterOPtion] = useState([]);
    const [isfilterLightbox, setIsfilterLightbox] = useState(false)
    const [limit, setLimit] = useState(10)
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
    const [columns, setColumns] = useState([
        {
            name: '#',
            cell: (row: any, index: number) => (((page - 1) * limit) + index + 1).toString().padStart(2, '0'),
            width: '50px',
            tblName: ''

        },
        {
            name: 'Referral ID',
            cell: (item: any, ind: number) => <span>{item.referral_id}</span>,
            sortable: false,
            width: "15%",
            tblName: 'referral_id'
        },
        {
            name: 'Referral Date/Time',
            cell: (item: any, ind: number) => <span>{moment(item.createdAt).format("DD/MM/YYYY")}</span>,
            sortable: false,
            width: "15%",
            tblName: 'createdAt'
        },
        {
            name: 'Patient Name',
            cell: (item: any, ind: number) => <span>{item.patient_name}</span>,
            selector: (item: any, ind: number) => item.patient_name,
            sortable: true,
            width: "20%",
            tblName: 'patient_name'
        },
        {
            name: 'Patient Age',
            cell: (item: any, ind: number) => <span>{item.age}</span>,
            sortable: false,
            width: "10%",
            tblName: 'age'
        },
        {
            name: 'NHS Number',
            width: "15%",
            cell: (item: any, ind: number) => <span>{formatNHSNumber(item?.patient_nhs_number)}</span>,
            sortable: false,
            tblName: 'patient_nhs_number'
        },
        {
            name: 'Source',
            width: "10%",
            cell: (item: any, ind: number) => <span>{item.status}</span>,
            sortable: false,
            tblName: 'status'
        },
        {
            name: 'action',
            width: '80px',
            cell: (item: any, ind: number) => <span>{item.action}</span>,
            sortable: false,
            tblName: 'action'
        }
    ]);


    const referralReducer = (state: any) => state?.referralReducer
    const referralData = createSelector(
        referralReducer,
        (state) => ({
            referralList: state.referralList,

        })
    );
    const { referralList } = useSelector(referralData);


    const fullDataNoLimit = (state: any) => state;

    const fullDataForFilter = createSelector(fullDataNoLimit, (state) => ({
        fullFilter: state.referralReducer.referralFullList?.referrals
    }));
    const { fullFilter } = useSelector(fullDataForFilter);


    const handleClick = (event: any, id: string) => {
        setAnchorEl(event.currentTarget);
        setSelectedUserId(id)
    };


    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChildValue = (value: string) => {
        setParentValue(value);
    };

    useEffect(() => {
        dispatch(changeTab('Referral'))
        dispatch(viewReferral({
            page,
            search,
            limit,

        }))
        clearSearch()

    }, [page, search, limit])





    useEffect(() => {
        if (parentValue && parentValue.length !== 0) {
            dispatch(viewReferral({
                limit,
                page,
                search,
                filter: JSON.stringify(parentValue)
            }));
        }
    }, [parentValue]);

    const handleNavigate = (type: string) => {
        if (type === 'view') {
            Navigate(`/referral/view/${selectedUserId}`)
        } else if (type === 'create-caseload') {
            Navigate(`/referral/create-caseload/${selectedUserId}`)
        }
    }
 

    useEffect(() => {
        setfilterOPtion(fullFilter);

        return () => {
            setfilterOPtion([]);
        };
    }, [fullFilter]);

    const handleFilter = () => {
        setIsfilterLightbox(true)
        if (parentValue.length === 0) {
            dispatch(viewReferral({ limit: '', page: '', search: '' }))
        } else {
            setfilterOPtion(fullFilter);
        }

    }

    return (
        <>
            <Box className="innerheader">
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item xs={6} className="searchInputbx">
                        <Search searchCallBack={(e: string) => setSearch(e)} searchValue={search}/>
                    </Grid>
                    <Grid item xs={6} display={"flex"} gap={2} justifyContent={"end"}>
      

                        <IconButton
                            size="large"
                            className={classes.iconStyle}
                            color='primary'
                            sx={{ border: `1px solid #6A2382` }}
                            onClick={() => handleFilter()}
                        >
                            <FilterAltIcon fontSize="medium" />
                        </IconButton>
                        <Button className="  btnStyle_v2" size="large" variant="outlined" onClick={() => Navigate("/referral/add")} startIcon={<AddCircleIcon />}>
                            Create Referral
                        </Button>

                    </Grid>
                </Grid>
                <Grid item lg={4} marginTop={2}>
                    <List component="div" disablePadding sx={{ display: 'flex', maxWidth: '500px' }}>
                        <ListItem disablePadding
                            onClick={() => {
                                Navigate("/referral")
                                dispatch(changeTab('Referral'))
                            }}
                            className={(location.pathname === '/referral' ? 'nav-active' : 'nav-Pagelink')}
                        >
                            <ListItemButton>
                                <ListItemIcon>
                                    {/* <AdminPanelSettingsIcon /> */}

                                    <Avatar src={newRef} alt="Logo" style={{ width: '24px', height: '24px', borderRadius: '0%', margin: 'auto' }} />
                                </ListItemIcon>
                                <ListItemText primary="New Referrals" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding
                            onClick={() => {
                                Navigate("/manage-caseload/active")
                                dispatch(changeTab('Active Referrals'))
                            }}
                            className={(location.pathname === '/manage-caseload/active' ? 'nav-active' : 'nav-Pagelink')}
                        >
                            <ListItemButton>
                                <ListItemIcon >
                                    {/* <AdminPanelSettingsIcon /> */}
                                    <Avatar src={activeRef} alt="Logo" style={{ width: '24px', height: '24px', borderRadius: '0%', margin: 'auto' }} />
                                </ListItemIcon>
                                <ListItemText primary="Active Referrals" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Grid>
         
         
            </Box>
            <Grid className='tableWrapper'>
                <Table columns={columns}
                    setLimitPerPage={(e: any) => setLimit(e)}
                    limit={limit}
                    data={referralList?.referrals && referralList?.referrals?.map((item: any, index: number) => {
                        return ({
                            ...item,
                            status: item.submitted_by === CONSTANT.SCOPE.SCHOOL ? (
                                <Typography className="activeStatus">Education</Typography>
                            ) : item.submitted_by === CONSTANT.SCOPE.CLIENT_ADMIN ? (
                                <Typography className="deactiveStatus">Admin</Typography>
                            ) : '',
                            age: calculateAge(item.patient_dob),
                            action:
                                <div key={index}>
                                    {/* <PendingOutlinedIcon
                                        aria-controls={`item-menu-${item?.id}`}
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                        style={{ cursor: 'pointer', color: '#6A2382', width: '33px', minHeight: '33px' }} >
                                    </PendingOutlinedIcon> */}

                                    <IconButton
                                        aria-controls={`item-menu-${item?.id}`}
                                        aria-haspopup="true"
                                        className="iconStyleAction"
                                        onClick={(e) => handleClick(e, item?.id)}
                                        color='primary'
                                        sx={{ border: `1px solid #6A2382` }} >

                                        <MoreHorizIcon fontSize="medium" />
                                    </IconButton>


                                    <Menu
                                        id={`item-menu-${item?.id}`}
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <MenuItem onClick={() => handleNavigate("view")} >
                                            <ListItemIcon>
                                                <VisibilityIcon />
                                            </ListItemIcon>
                                            View
                                        </MenuItem>

                                        <MenuItem
                                            onClick={() => handleNavigate("create-caseload")}
                                        >     <ListItemIcon>
                                                <AddCircleIcon />
                                            </ListItemIcon>
                                            Accept Referral
                                        </MenuItem>
                                        <MenuItem
                                        >
                                            <ListItemIcon>
                                                <DeleteIcon />
                                            </ListItemIcon>
                                            Reject
                                        </MenuItem>
                                    </Menu>
                                </div>
                        })

                    }
                    )}
                    paginationTotalRows={referralList.totalPages}
                    pagination={true}
                    setCurrentPage={(e: any) => setPage(e)} />
            </Grid>
            {/* <Suspense fallback={<div>Loading...</div>}>
                {filterOPtion && filterOPtion?.length !== 0 &&
                    <FilterBox isOpen={isfilterLightbox} onClose={closeModal} data={filterOPtion} columns={columns} onChildValue={handleChildValue} selectedParentValue={parentValue}></FilterBox>
                }
            </Suspense> */}
        </>
    )
}

export default memo(Index)


